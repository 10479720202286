import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { View, StyleSheet, Dimensions, Platform, Keyboard } from 'react-native';
import TaskImage from './TaskImage';
import { Input, Text, Button } from '@ui-kitten/components';
import { isMobileTablet } from '../../utils/isMobileTablet.js';

const ImageText = ({ task, onAnswer }) => {
  const intl = useIntl();
  const [answer, setAnswer] = useState('');
  const [keyboardOffset, setKeyboardOffset] = useState(0);
  const [error, setNotEmptyError] = useState(false);
  const [screenWidth, setScreenWidth] = useState(
    Math.round(Dimensions.get('window').width),
  );
  const setSizes = () => {
    setScreenWidth(Math.round(Dimensions.get('window').width));
  };

  const liftUp = (keyboard) => {
    setKeyboardOffset(keyboard.endCoordinates.height);
  };
  const liftDown = (keyboard) => {
    setKeyboardOffset(0);
  };

  useEffect(() => {
    Dimensions.addEventListener('change', setSizes);
    if (Platform.OS === 'ios') {
      Keyboard.addListener('keyboardDidShow', liftUp);
      Keyboard.addListener('keyboardDidHide', liftDown);
    }

    return () => {
      Dimensions.removeEventListener('change', setSizes);
      if (Platform.OS === 'ios') {
        Keyboard.removeListener('keyboardDidShow', liftUp);
        Keyboard.removeListener('keyboardDidHide', liftDown);
      }
    };
  }, []);

  const handleAnswer = () => {
    answer === '' ? setNotEmptyError(true) : onAnswer({ answer });
  };

  const onChangeAnswer = (value) => {
    setAnswer(value);
    setNotEmptyError(false);
  };

  return (
    <View style={styles.container}>
      {task && <TaskImage task={task} />}
      <View style={[styles.inputContainer, { bottom: keyboardOffset }]}>
        <Input
          placeholder={intl.formatMessage({ id: 'image.text.type_answer' })}
          status="primary"
          autoFocus
          caption={
            error &&
            (() => (
              <Text style={styles.caption}>
                {intl.formatMessage({ id: 'image.text.not_empty' })}
              </Text>
            ))
          }
          onEndEditing={({ nativeEvent }) => {
            handleAnswer();
          }}
          onKeyPress={({ nativeEvent }) => {
            nativeEvent.key === 'Enter' && handleAnswer();
          }}
          style={styles.input}
          value={answer}
          onChangeText={onChangeAnswer}
        />
        <View style={styles.buttons}>
          <Button
            style={styles.button}
            onPress={() => onAnswer({ skip: true })}
          >
            {intl.formatMessage({ id: 'skip' })}
          </Button>
          <Button style={styles.button} onPress={handleAnswer}>
            {intl.formatMessage({ id: 'send' })}
          </Button>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  inputContainer: {
    position: 'absolute',
    alignSelf: 'center',
    ...Platform.select({
      web: {
        width: '100%',
      },
    }),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'rgba(96, 100, 109, 0.5)',
    paddingVertical: 20,
    paddingHorizontal: 10,
  },
  input: {
    ...(isMobileTablet() ? { flex: 1 } : { width: 300 }),
    justifyContent: 'center',
    height: 40,
    marginBottom: -4,
  },
  caption: {
    paddingVertical: 5,
    color: 'red',
  },
  button: {
    marginLeft: 10,
    height: 40,
  },
  buttons: {
    flexDirection: 'row',
  },
});

export default ImageText;
