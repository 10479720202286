import React from 'react';
import { Switch, View, StyleSheet } from 'react-native';
import { Text } from '@ui-kitten/components';
import { TextInput } from 'react-native-gesture-handler';

export function ToggleSwitch({ label, checked, onChange }) {
  return (
    <View style={styles.container}>
      <Switch
        trackColor={{ true: 'rgb(57, 106, 246)', false: 'grey' }}
        onValueChange={onChange}
        value={checked}
      />
      <View style={styles.label}>
        <Text category="s1">{label}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 5,
  },
  label: {
    marginLeft: 5,
  },
});
