import React, { useState, useEffect } from 'react';
import ImageZoom from 'react-native-image-pan-zoom';
import { Dimensions, Image } from 'react-native';

const NativeZoomImage = ({ imageSrc }) => {
  const [screenWidth, setScreenWidth] = useState(Math.round(Dimensions.get('window').width));
  const [screenHeight, setScreenHeight] = useState(Math.round(Dimensions.get('window').height));

  const setSizes = () => {
    setScreenWidth(Math.round(Dimensions.get('window').width));
    setScreenHeight(Math.round(Dimensions.get('window').height));
  };

  useEffect(() => {
    Dimensions.addEventListener('change', setSizes);
    return () => {
      Dimensions.removeEventListener('change', setSizes);
    };
  }, []);

  return (
    <ImageZoom
      cropWidth={screenWidth}
      cropHeight={screenHeight}
      imageWidth={screenWidth}
      imageHeight={screenHeight}
    >
      <Image
        source={{ uri: imageSrc }}
        style={{
          resizeMode: 'contain',
          alignSelf: 'center',
          width: screenWidth,
          height: screenHeight - 52,
        }}
      />
    </ImageZoom>
  );
};

export default NativeZoomImage;
