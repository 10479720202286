import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Task } from '../../../hooks/useTask';
import { useKeyboard } from './useKeyboard';
import AllButtons from './AllButtons';
import { Text } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import { isMobileTablet } from '../../../utils/isMobileTablet';

interface Props {
  task: Task;
  onAnswer: (answer: object) => Promise<void>;
}

const TextClass = ({ task, onAnswer }: Props) => {
  useKeyboard(task, onAnswer);

  return (
    task && (
      <View style={styles.container}>
        <ScrollView style={styles.innerContainer}>
          <Text style={styles.text}>{task.text}</Text>
        </ScrollView>
        <AllButtons task={task} onAnswer={onAnswer} />
      </View>
    )
  );
};

export default TextClass;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    ...Platform.select({
      web: {
        fontSize: isMobileTablet() ? 15 : 20,
      },
      default: {
        fontSize: 15,
      },
    }),
  },
  innerContainer: {
    width: '100%',
    marginBottom: 80,
    padding: 20,
    backgroundColor: 'white',
  },
});
