import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { Select, IndexPath, SelectItem, Text } from '@ui-kitten/components';
import { useIntl } from 'react-intl';
import { LANGUAGES } from '../../constants/AppConstants';
import { actions } from '../../store/reducers';
const enFlag = require('../../assets/images/en.png');
const beFlag = require('../../assets/images/be.png');

const flagMap = {
  ['en']: enFlag,
  ['be-BY']: beFlag,
};

const LanguageSelector = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.settingsReducer.locale);

  const [idx, setIdx] = React.useState<IndexPath>(
    new IndexPath(LANGUAGES.indexOf(locale)),
  );

  return (
    <View style={styles.container}>
      <Text style={styles.header} appearance="hint" category="s1">
        {intl.formatMessage({ id: 'language' })}
      </Text>
      <Select
        style={styles.select}
        selectedIndex={idx}
        value={() => (
          <View style={styles.value}>
            <Image source={flagMap[LANGUAGES[idx.row]]} style={styles.flag} />
            <Text category="s1">
              {intl.formatMessage({ id: LANGUAGES[idx.row] })}
            </Text>
          </View>
        )}
        onSelect={(index) => {
          dispatch(actions.setLocale(LANGUAGES[(index as IndexPath).row]));
          setIdx(index as IndexPath);
        }}
      >
        <SelectItem
          title={intl.formatMessage({ id: 'en' })}
          accessoryLeft={() => <Image source={enFlag} style={styles.flag} />}
        />
        <SelectItem
          title={intl.formatMessage({ id: 'be-BY' })}
          accessoryLeft={() => <Image source={beFlag} style={styles.flag} />}
        />
      </Select>
    </View>
  );
};

export default LanguageSelector;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
  },
  value: {
    flexDirection: 'row',
  },
  flag: {
    width: 40,
    height: 20,
    marginRight: 10,
  },
  header: {
    textAlign: 'center',
    marginBottom: 5,
  },
  select: {
    marginBottom: 20,
    width: 200,
  },
});
