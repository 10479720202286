import React, { useState, useEffect } from 'react';
import { StyleSheet, View, FlatList, ActivityIndicator } from 'react-native';
import { ApplicationPreview } from './ApplicationPreview';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store/reducers';
import NoContent from './NoContent';
import { useIntl } from 'react-intl';
import { useCheckToken } from '../../hooks/useCheckToken';

export default function PublishedApplications({ navigation }) {
  const intl = useIntl();
  const { applicationsWithUserStatuses, count } = useSelector(
    (state) => state.applicationsReducer.publishedApplications,
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const checkToken = useCheckToken(navigation);

  const getPublishedApplications = async (actionDispatch) => {
    try {
      setIsLoading(true);
      await actionDispatch();
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  const loadMore = async () => {
    if (applicationsWithUserStatuses.length >= count) {
      return;
    }

    const nextPage = page + 1;
    await getPublishedApplications(() =>
      dispatch(actions.setPublishedApplications(nextPage)),
    );
    setPage(nextPage);
  };

  const onLoad = async () => {
    try {
      await checkToken();
      await getPublishedApplications(() =>
        dispatch(actions.resetPublishedApplications()),
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  if (!applicationsWithUserStatuses.length) {
    return (
      <NoContent
        message={intl.formatMessage({ id: 'applications.no_applications' })}
      />
    );
  }

  return (
    <FlatList
      contentContainerStyle={{
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
      keyExtractor={(item: any) => item.application.id.toString()}
      data={applicationsWithUserStatuses}
      renderItem={({ item }) => (
        <ApplicationPreview
          application={item.application}
          navigation={navigation}
          isUserJoined={false}
          isUserBlocked={item.isUserBlocked}
        />
      )}
      onEndReached={loadMore}
      onEndReachedThreshold={0.5}
      ListFooterComponent={() =>
        isLoading ? (
          <View style={styles.loader}>
            <ActivityIndicator animating size="large" />
          </View>
        ) : null
      }
    />
  );
}

const styles = StyleSheet.create({
  loader: {
    paddingVertical: 10,
    backgroundColor: 'white',
  },
});
