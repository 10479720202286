import React from 'react';
import { Platform, View } from 'react-native';
import WebZoomImage from './WebZoomImage';
import NativeZoomImage from './NativeZoomImage';

const TaskImage = ({ task }) => {
  return (
    <View style={{ backgroundColor: 'black' }}>
      {Platform.OS === 'ios' || Platform.OS === 'android' ? (
        <NativeZoomImage imageSrc={task.image} />
      ) : (
        <WebZoomImage imageSrc={task.image} />
      )}
    </View>
  );
};

export default TaskImage;
