import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Ionicons } from '@expo/vector-icons';

import { TouchableOpacity } from 'react-native-gesture-handler';

export const IconButton = ({ handleClick, name, size, color }) => {
  return (
    <TouchableOpacity onPress={handleClick} style={{ justifyContent: 'center' }}>
      <Ionicons name={name} size={size || 25} color={color || 'black'} />
    </TouchableOpacity>
  );
};

IconButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};
