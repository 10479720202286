import { AsyncStorage } from 'react-native';
import { useEffect } from 'react';

export const useCheckToken = (navigation) => {
  const checkToken = async () => {
    const userToken = await AsyncStorage.getItem('userToken');
    if (!userToken) {
      navigation.navigate('Auth');
      return;
    }
  };

  return checkToken;
};
