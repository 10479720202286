import React from 'react';
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import createAnimatedSwitchNavigator from 'react-navigation-animated-switch';
import { createBrowserApp } from '@react-navigation/web';

import AuthLoadingScreen from './../screens/auth/AuthLoadingScreen';
import AuthStack from './AuthStack';

import MainTabNavigator from './MainTabNavigator';
import { Transition } from 'react-native-reanimated';

import { Platform } from 'react-native';
import { injectIntl } from 'react-intl';
import { Linking } from 'expo';

const AppContainer = createAppContainer(
  createSwitchNavigator(
    {
      // You could add another route here for authentication.
      // Read more at https://reactnavigation.org/docs/en/auth-flow.html
      Main: {
        screen: MainTabNavigator,
        path: '',
      },
      AuthLoading: AuthLoadingScreen,
      Auth: {
        screen: AuthStack,
        path: '',
      },
    },
    {
      initialRouteName: 'AuthLoading',
    },
  ),
);
const App =
  Platform.OS === 'web'
    ? createBrowserApp(AppContainer, { history: 'browser' })
    : AppContainer;

// export default injectIntl((props) => <App screenProps={props} />);
export default injectIntl((props) => {
  const prefix = Linking.makeUrl('/');

  return (
    <App
      screenProps={props}
      {...(Platform.OS !== 'web' && { uriPrefix: prefix })}
    />
  );
});
// const AppNavigator = createAnimatedSwitchNavigator({
//   // You could add another route here for authentication.
//   // Read more at https://reactnavigation.org/docs/en/auth-flow.html
//   Main: MainTabNavigator,
//   Auth: AuthStack,
//   AuthLoading: AuthLoadingScreen,
// },
//   {
//     initialRouteName: 'AuthLoading',
//     transition: (
//       <Transition.Together>
//         <Transition.Out
//           type="slide-bottom"
//           durationMs={400}
//           interpolation="easeIn"
//         />
//         <Transition.In type="fade" durationMs={500} />
//       </Transition.Together>
//     ),
//   }
// );

// function getActiveRouteName(navigationState) {
//   if (!navigationState) {
//     return null;
//   }
//   const route = navigationState.routes[navigationState.index];
//   // dive into nested navigators
//   if (route.routes) {
//     return getActiveRouteName(route);
//   }
//   return route.routeName;
// }

// const AppContainer = createAppContainer(AppNavigator);

// export default AppContainer;
// export default () => {
//   <AppContainer
//     onNavigationStateChange={(prevState, currentState, action) => {
//       const currentRouteName = getActiveRouteName(currentState);
//       const previousRouteName = getActiveRouteName(prevState);

//       if (previousRouteName !== currentRouteName) {

//         // https://reactnavigation.org/docs/en/screen-tracking.html
//         // the line below uses the @react-native-firebase/analytics tracker
//         // change the tracker here to use other Mobile analytics SDK.
//         // analytics().setCurrentScreen(currentRouteName, currentRouteName);
//       }
//     }}
//   />
// };
