import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Modal, Text } from '@ui-kitten/components';

export const ModalMessage = ({ message, visible, setVisible }) => {
  return (
    <View>
      <Modal
        visible={visible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setVisible(false)}
      >
        <Card style={styles.container} disabled={true}>
          <Text style={styles.text}>{message}</Text>
          <Button onPress={() => setVisible(false)} size="small">
            OK
          </Button>
        </Card>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 300,
  },
  text: {
    textAlign: 'center',
    marginBottom: 10,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
