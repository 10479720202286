import { useReducer } from 'react';
import { AsyncStorage } from 'react-native';
import authService from '../../../services/authService';

const SET_PROP = 'SET_PROP';
const SET_EMAIL = 'SET_EMAIL';
const SET_PASSWORD = 'SET_PASSWORD';

const initialState = {
  email: '',
  password: '',
  error: null,
  passwordVisible: false,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROP:
      return { ...state, ...action.payload };
    case SET_EMAIL:
      return { ...state, email: action.payload, error: null };
    case SET_PASSWORD:
      return { ...state, password: action.payload, error: null };
    default:
      break;
  }
};

export const actions = {
  setProp: (propObj) => ({
    type: SET_PROP,
    payload: propObj,
  }),
};

export const useSignIn = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setProp = (propObj) =>
    dispatch({
      type: SET_PROP,
      payload: propObj,
    });

  const onChangeEmail = (value) => {
    dispatch({
      type: SET_EMAIL,
      payload: value,
    });
  };

  const onChangePassword = (value) => {
    dispatch({
      type: SET_PASSWORD,
      payload: value,
    });
  };

  const onSignInButtonPress = async () => {
    try {
      setProp({ isLoading: true });
      const result = await authService.login(state.email, state.password);
      return result.data.token;
    } catch (e) {
      setProp({
        error: e.response
          ? e.response.data.errorMsg
          : 'Server is not available :(',
      });
    }
    setProp({ isLoading: false });
  };

  return {
    state,
    setProp,
    onChangeEmail,
    onChangePassword,
    onSignInButtonPress,
  };
};
