import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { StyleSheet, Text } from 'react-native';

const AnswerButton = ({ labelText, isDekstop, index, onAnswer, isSelected }) => {
  const buttonText = isDekstop ? `${index + 1}. ${labelText}` : labelText;

  return (
    <TouchableOpacity
      style={[styles.actionButton, isSelected && styles.selectedAnswer]}
      onPress={onAnswer}
    >
      <Text>{buttonText}</Text>
    </TouchableOpacity>
  );
};

export default AnswerButton;

AnswerButton.propTypes = {
  labelText: PropTypes.string.isRequired,
  isDekstop: PropTypes.bool.isRequired,
  index: PropTypes.number,
  onAnswer: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

const styles = StyleSheet.create({
  actionButton: {
    marginHorizontal: 10,
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: '#fefefe',
    borderRadius: 5,
  },
  selectedAnswer: {
    backgroundColor: '#61BD50',
  },
});
