import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStorage, Platform } from 'react-native';

import { isMobileTablet } from '../utils/isMobileTablet';
import { actions } from '../store/reducers';
import { prefetchImage } from '../utils/prefetchImage';
import { storeTask, removeTaskFromStorage } from '../utils/storeTask';
import taskService from '../services/taskService';
import { useIntl } from 'react-intl';
import { useDesktopNotifications } from '../utils/displayTaskNotifications';
import { sendNativeLocalNotification } from '../utils/sendLocalNotification';
import { playSound } from './playSound';

const LOAD_REPEATES = 3;

export type Task = {
  uid: number;
  type: string;
  created: Date;
  image: string;
  timeout: number;
  classes?: object;
  selected?: string;
  text: string;
  description?: string;
};

export const useTask = (setMessage, setIsLoading) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const displayDesktopNotifications = useDesktopNotifications();

  const setIsConnected = (isConnected: boolean) =>
    dispatch(actions.setIsConnected(isConnected));
  const setTask = (task: object) => dispatch(actions.setTask(task));

  const [timeoutDelay, setTimeoutDelay] = useState<number>(0);
  const [taskImage, setTaskImage] = useState(null);
  const { isConnected, willUserStop, task } = useSelector(
    (state) => state.homeReducer,
  );

  const resetTask = () => {
    setTaskImage(null);
    setTask(null);
  };

  const handleTaskImage = async (task: Task, loadTimes = 0) => {
    const isFetched = await prefetchImage(task.image);

    if (!isFetched && loadTimes < LOAD_REPEATES) {
      return await new Promise((resolve) =>
        setTimeout(
          async () => resolve(await handleTaskImage(task, ++loadTimes)),
          1000,
        ),
      );
    } else if (!isFetched && loadTimes >= LOAD_REPEATES) {
      setMessage({
        visible: true,
        message: intl.formatMessage({ id: 'messages.unable_to_load' }),
      });
      setIsLoading(false);
      setIsConnected(false);
    } else if (isFetched) {
      setIsLoading(false);
    }

    return isFetched;
  };

  const onTaskLoad = async (task: Task) => {
    if (task.type.includes('image-')) {
      setIsLoading(true);
      const imageLoaded = await handleTaskImage(task);

      if (!imageLoaded) {
        return;
      }
    }

    if (isTimeout(task.created, task.timeout)) {
      setIsConnected(false);
    } else {
      setTimeoutDelay(Number(Date.now()) - Number(task.created));
      setTaskImage({});
      storeTask(task);

      // disable simple notifications (we have push notifications)
      // if (Platform.OS === 'web' && !isMobileTablet()) {
      //   displayDesktopNotifications(task);
      //   playSound();
      // }

      sendNativeLocalNotification();
      setTask(task);
    }

    setIsLoading(false);
  };

  const onTaskFinish = ({ finished }) => {
    if (finished) {
      setIsConnected(false);
      Platform.OS !== 'web' &&
        setMessage({
          message: 'Task was skipped because of timeout',
          visible: true,
        });
    }
    resetTask();
    removeTaskFromStorage();
  };

  const getTaskFromStorage = async () => {
    const task = JSON.parse(
      await AsyncStorage.getItem('task', (e) => !e ?? console.log(e)),
    );

    if (task && !isTimeout(task.created, task.timeout)) {
      setTimeoutDelay(Date.now() - task.created);
      setTask(task);
      onTaskLoad(task);
    } else if (task && isTimeout(task.created, task.timeout)) {
      removeTaskFromStorage();
    }
  };

  const handleAnswer = async (answer) => {
    const id = task.id; // taskLog id
    if (willUserStop) {
      setIsConnected(false);
    }
    resetTask();
    removeTaskFromStorage();
    if (!isConnected) {
      setIsConnected(true);
    }

    try {
      await taskService.sendAnswer(id, answer);
    } catch (e) {
      console.log(e);
    }
  };

  return {
    handleAnswer,
    setTask,
    resetTask,
    onTaskLoad,
    getTaskFromStorage,
    timeoutDelay,
    taskImage,
    onTaskFinish,
  };
};

export function isTimeout(start: Date, timeout: number) {
  return timeout !== 0 && Date.now() > timeout + Number(start);
}
