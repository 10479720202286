import React from 'react';
import { View, Text, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

interface Props {
  size?: number;
  color?: string;
}

const LockIcon = ({ size, color }: Props) => {
  return (
    <Ionicons
      name={Platform.OS === 'ios' ? 'ios-lock' : 'md-lock'}
      size={size || 24}
      color={color || 'black'}
    />
  );
};

export default LockIcon;
