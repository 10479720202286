import { Platform, AppState, AsyncStorage } from 'react-native';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useState, useEffect } from 'react';

import { actions } from '../store/reducers';
import { useDesktopNotifications } from '../utils/displayTaskNotifications';
import wsService from '../services/wsService';
import { useTask } from './useTask';
import { useIntl } from 'react-intl';
import { playSound } from './playSound';

export const useSocket = (navigation) => {
  const intl = useIntl();
  const profile = useSelector((state) => state.settingsReducer);
  const { isConnected } = useSelector((state) => state.homeReducer);
  const dispatch = useDispatch();
  const { getState } = useStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState({
    visible: false,
    message: '',
  });

  const {
    resetTask,
    onTaskLoad,
    getTaskFromStorage,
    timeoutDelay,
    taskImage,
    handleAnswer,
    onTaskFinish,
  } = useTask(setMessage, setIsLoading);

  const setIsConnected = (isConnected: boolean) =>
    dispatch(actions.setIsConnected(isConnected));

  const setIsServerShutdown = (isServerShutdown: boolean) =>
    dispatch(actions.setIsServerShutdown(isServerShutdown));

  const stopListeningForTasks = () => {
    wsService.close();
  };

  const onTaskReceived = async (incomingTask) => {
    const newTask = { ...incomingTask, created: Date.now() };

    await playSound();

    await onTaskLoad(newTask);
  };

  async function startListeningForTasks() {
    await wsService.connect(
      onTaskReceived,
      (e) => {
        console.log('WS Connected');
        setIsConnected(true);
      },
      (e) => {
        console.log('WS Disconnected');
        setIsConnected(false);
      },
      (e) => {
        console.log('Unauthenticated');
        navigation.navigate('Auth');
      },
      (e) => {
        console.log('Another Device!');
        setIsConnected(false);
        setMessage({
          visible: true,
          message: intl.formatMessage({ id: 'messages.another_device' }),
        });
      },
      (e) => {
        console.log('Timeout Disconnect!');
        stopListeningForTasks();
        setIsLoading(false);
      },
      (e) => {
        console.log('Reconnect failed');
        setIsConnected(false);
        setMessage({
          visible: true,
          message: intl.formatMessage({ id: 'messages.connection_interrupted' }),
        });
      },
      (e) => {
        console.log('Server shutdown');
        setIsServerShutdown(true);
      },
      (e) => {
        const { isServerShutdown } = getState()['homeReducer'];
        return isServerShutdown;
      },
    );
  }

  const connectOnTask = async () => {
    if (Platform.OS === 'web') {
      return;
    }

    const status = AppState.currentState;
    const shouldCheckTask = await AsyncStorage.getItem('shouldCheckTask');

    if (status === 'active' && shouldCheckTask) {
      setIsConnected(true);
      AsyncStorage.removeItem('shouldCheckTask');
    }
  };

  useEffect(() => {
    if (isConnected) {
      startListeningForTasks();
    } else {
      stopListeningForTasks();
      if (Platform.OS === 'web' || AppState.currentState === 'active') {
        resetTask();
      }
    }
  }, [isConnected]);



  return {
    isLoading,
    message,
    setMessage,
    getTaskFromStorage,
    timeoutDelay,
    taskImage,
    handleAnswer,
    onTaskFinish,
    connectOnTask,
  };
};
