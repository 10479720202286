import React, { useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Task } from '../../hooks/useTask';

interface Props {
  task: Task;
}

const TaskDescription = ({ task }: Props) => {
  const [isVisible, setIsVisible] = useState(true);

  return isVisible ? (
    <TouchableOpacity
      style={styles.container}
      onPress={() => setIsVisible(false)}
    >
      <Text style={styles.text}>{task.description}</Text>
    </TouchableOpacity>
  ) : null;
};

export default TaskDescription;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    maxWidth: 300,
    alignSelf: 'center',
    borderRadius: 5,
    top: 10,
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 10,
  },
  text: {
    color: 'white',
  },
});
