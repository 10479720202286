import React from 'react';
import { Ionicons } from '@expo/vector-icons';

interface Props {
  size?: number;
  color?: string;
}

const EmailIcon = ({ size, color }: Props) => {
  return <Ionicons name="ios-at" size={size || 24} color={color || 'black'} />;
};

export default EmailIcon;
