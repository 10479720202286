import React from 'react';
import { Task } from '../../hooks/useTask';
import ImageClass from './ClassTasks/ImageClass';
import ImageText from './ImageText';
import ImageArea from './ImageArea/ImageArea';
import TextClass from './ClassTasks/TextClass';

const TaskTypes = {
  'image-class': ImageClass,
  'image-text': ImageText,
  'image-area': ImageArea,
  'text-class': TextClass,
};

interface Props {
  task: Task;
  onAnswer: (answer: object) => Promise<void>;
}

const TaskRouter = ({ task, onAnswer }: Props) => {
  const TaskContent = TaskTypes[task.type];
  return <TaskContent task={task} onAnswer={onAnswer} />;
};

export default TaskRouter;
