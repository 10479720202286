import { AsyncStorage } from 'react-native';
import { Task } from '../hooks/useTask';

export const storeTask = async (task: Task) => {
  const taskFromStore = await AsyncStorage.getItem('task', (e) => !e ?? console.log(e));
  
  if (taskFromStore === null) {
    AsyncStorage.setItem('task', JSON.stringify(task), (e) => !e ?? console.log(e));
  }
};

export const removeTaskFromStorage = async () => {
  await AsyncStorage.removeItem('task');
};
