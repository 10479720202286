import React, { useEffect } from 'react';
import 'intl';
import { useSelector, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import enMessages from './en';
import beMessages from './be';
import { actions } from '../store/reducers';
import * as Localization from 'expo-localization';
import { LANGUAGES } from '../constants/AppConstants';

const allMessages = {
  ['en']: enMessages,
  ['be-BY']: beMessages,
};

let localeFromDevice = Localization.locale;
if (!LANGUAGES.includes(localeFromDevice)) {
  localeFromDevice = 'en';
}

export function I18nProvider({ children }) {
  const dispatch = useDispatch();
  let { locale } = useSelector((state) => state.settingsReducer);

  const messages = allMessages[locale || localeFromDevice];

  useEffect(() => {
    dispatch(actions.setLocale(localeFromDevice));
  }, []);

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale="en">
      {children}
    </IntlProvider>
  );
}
