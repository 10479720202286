import { API } from './../constants/AppConstants';
import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: API,
});

export default {
  setUserToken: (token) => {
    axiosInstance.defaults.headers['X-Auth-Token'] = token;
  },
  login: (email, password) => {
    return axiosInstance.post('/auth/login', { email, password });
  },
  isValid: () => {
    return axiosInstance.get('/auth/check');
  },
  getUser: () => {
    return axiosInstance.get('/user');
  },
  updateUser: (user) => {
    return axiosInstance.put('/user', { ...user });
  },
  getHeader: () => {
    return axiosInstance.defaults.headers['X-Auth-Token'];
  },
  updatePassword: (password) => {
    return axiosInstance.put('/auth/update/password', { password });
  },
  recoverPassword: (email) => {
    return axiosInstance.post('/auth/recover', { email });
  },
  updateStripeAccount: () => {
    return axiosInstance.post('/user/stripe-account');
  },
  loginStripeAccount: () => {
    return axiosInstance.get('/user/stripe-login');
  },
  getUserBalance: () => {
    return axiosInstance.get('/user/balance');
  },
  payoutMoney: () => {
    return axiosInstance.get('/user/payout');
  },
};
