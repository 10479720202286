import { Image } from 'react-native';

export const prefetchImage = async (imageSrc: string) => {
  try {
    await Image.prefetch(imageSrc);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
