import React from 'react';
import { View } from 'react-native';
import TaskImage from '../TaskImage';
import AllButtons from './AllButtons';
import { Task } from '../../../hooks/useTask';
import { useKeyboard } from './useKeyboard';

interface Props {
  task: Task;
  onAnswer: (answer: object) => Promise<void>;
}

export default function ImageClass({ task, onAnswer }: Props) {
  useKeyboard(task, onAnswer);

  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {task && <TaskImage task={task} />}
      <AllButtons task={task} onAnswer={onAnswer} />
    </View>
  );
}
