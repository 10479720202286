import React, { useState, useEffect } from 'react';
import { StyleSheet, View, AsyncStorage } from 'react-native';
import { Button, Input, Text } from '@ui-kitten/components';
import authService from '../../../services/authService';
import TabBarIcon from '../../../components/TabBarIcon';
import { TouchableOpacity } from 'react-native-gesture-handler';
import AuthImageOverlay from '../../../components/AuthImageOverlay';
import BigButton from '../../../components/BigButton';

export default ({ navigation }): React.ReactElement => {
  const [password, setPassword] = useState<string>();
  const [error, setError] = useState<string>();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const tokenFromParam = navigation.getParam('token', null);
    if (tokenFromParam) {
      setToken(tokenFromParam);
      authService.setUserToken(tokenFromParam);
    }
  }, []);

  const onPasswordIconPress = (): void => {
    setPasswordVisible(!passwordVisible);
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
    setError(null);
  };

  const onConfirm = async (password: string, token: string) => {
    try {
      const response = await authService.updatePassword(password);
      await AsyncStorage.setItem('userToken', token);
      authService.setUserToken(token);
      navigation.navigate('Main');
    } catch (e) {
      console.log(e);
      setError('Server error');
    }
  };

  const eyeIcon = () => (
    <TouchableOpacity onPress={onPasswordIconPress}>
      <TabBarIcon name={passwordVisible ? 'ios-eye' : 'ios-eye-off'} />
    </TouchableOpacity>
  );

  return (
    <AuthImageOverlay>
      <View style={styles.headerContainer}>
        <Text category="h1" status="control" style={styles.headerText}>
          {'Change your password'}
        </Text>
      </View>
      <View style={styles.formContainer}>
        <Input
          style={styles.passwordInput}
          status="control"
          placeholder="Password"
          accessoryRight={eyeIcon}
          value={password}
          secureTextEntry={!passwordVisible}
          onChangeText={onChangePassword}
        />
        {error && (
          <Text style={styles.error} category="s1" status="control">
            {error}
          </Text>
        )}
      </View>
      <BigButton title="confirm" onPress={() => onConfirm(password, token)} />
    </AuthImageOverlay>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 216,
  },
  headerText: {
    textAlign: 'center',
  },
  formContainer: {
    flex: 1,
    marginTop: 32,
    paddingHorizontal: 16,
    minWidth: 300,
  },
  error: {
    color: 'red',
  },
  signInButton: {
    marginHorizontal: 16,
    marginBottom: 50,
    minWidth: 270,
  },
  signUpButton: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  passwordInput: {
    marginTop: 16,
  },
});
