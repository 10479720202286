import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import authService from '../../../services/authService';
import { actions } from '../../../store/reducers';

export const usePayoutSettings = () => {
  const intl = useIntl();
  const [message, setMessage] = useState({ message: '', visible: false });
  const dispatch = useDispatch();

  const payoutMoney = async () => {
    try {
      const response = await authService.payoutMoney();
      setMessage({
        message: intl.formatMessage({ id: 'settings.payouts.success' }),
        visible: true,
      });
      dispatch(actions.setUserSettings());
    } catch (e) {
      setMessage({
        message: intl.formatMessage({ id: 'settings.payouts.failure' }),
        visible: true,
      });
      console.log(e);
    }
  };

  const redirectToAccountUpdate = async () => {
    try {
      const {
        data: { link },
      } = await authService.updateStripeAccount();

      window.location.href = link;
    } catch (e) {
      setMessage({
        message: intl.formatMessage({ id: 'settings.stripe.update.failure' }),
        visible: true,
      });
    }
  };

  const stripeLogin = async () => {
    try {
      const {
        data: { link },
      } = await authService.loginStripeAccount();

      window.location.href = link;
    } catch (e) {
      setMessage({
        message: intl.formatMessage({ id: 'settings.stripe.update.failure' }),
        visible: true,
      });
    }
  };

  return {
    payoutMoney,
    redirectToAccountUpdate,
    stripeLogin,
    message,
    setMessage,
  };
};
