import { Platform, AsyncStorage } from 'react-native';
import UIFx from 'uifx';

const bellSoundFile = require('../assets/sounds/bell.mp3');

const bellSound =
  Platform.OS === 'web'
    ? new UIFx(bellSoundFile, {
        volume: 0.5,
        throttleMs: 100,
      })
    : null;

export const playSound = async () => {
  const notificationsEnabled = await AsyncStorage.getItem('notificationsEnabled');

  if (Platform.OS === 'web' && document.hasFocus()) {
    return;
  } else if (bellSound && JSON.parse(notificationsEnabled)) {
    bellSound.play();
  }
};
