import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { Platform } from 'react-native';

interface Props {
  size?: number;
  color?: string;
}

const WarningIcon = ({ size, color }: Props) => {
  return (
    <Ionicons
      name={Platform.OS === 'ios' ? 'ios-warning' : 'md-warning'}
      size={size || 24}
      color={color || 'red'}
    />
  );
};

export default WarningIcon;
