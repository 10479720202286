import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Modal, Card, Text, Button } from '@ui-kitten/components';
import { ProfileSetting } from '../extra/profile-setting.component';
import { useIntl } from 'react-intl';
import { Profile } from '../Edit/useEditSettings';
import authService from '../../../services/authService';
import WarningIcon from '../../../components/WarningIcon';
import { centToDollars } from '../../../utils/textUtils';
import { StripeBalance } from './StripeBalance';
import { convertBalanceObject, Balance } from './convertBalanceObject';
import SpinnerButton from '../../../components/SpinnerButton';
import { ModalMessage } from '../../../components/ModalMessage';
import { actions } from '../../../store/reducers';
import { usePayoutSettings } from './usePayoutSettings';

interface Props {
  profile: Profile;
  setIsError: Dispatch<SetStateAction<boolean>>;
}

const PayoutsSettings = ({ profile, setIsError }: Props) => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const {
    payoutMoney,
    redirectToAccountUpdate,
    stripeLogin,
    message,
    setMessage,
  } = usePayoutSettings();

  return (
    <View>
      <Button style={styles.marginTop} onPress={() => setShow(true)}>
        {intl.formatMessage({ id: 'settings.payouts' })}
      </Button>
      <ModalMessage
        {...message}
        setVisible={(bool) => setMessage({ ...message, visible: bool })}
      />
      <Modal
        visible={show}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setShow(false)}
      >
        <Card style={styles.container} disabled={true}>
          <ProfileSetting
            style={styles.setting}
            hint={intl.formatMessage({ id: 'settings.balance' })}
            value={centToDollars(profile.balance || 0).toString()}
          />
          {!profile.isChargesEnabled && (
            <View>
              <Card status="danger">
                <View style={styles.contentCenter}>
                  <WarningIcon size={20} />
                </View>
                <Text>
                  {intl.formatMessage({ id: 'settings.payouts.incomplete' })}
                </Text>
              </Card>
              <Button
                style={styles.marginTop}
                onPress={redirectToAccountUpdate}
              >
                {intl.formatMessage({ id: 'settings.payouts.update' })}
              </Button>
            </View>
          )}
          {profile.stripeAccountId && (
            <View>
              <Button onPress={stripeLogin}>
                {intl.formatMessage({ id: 'settings.login_stripe' })}
              </Button>
            </View>
          )}
          {profile.isChargesEnabled && (
            <View style={styles.marginTop}>
              <SpinnerButton disabled={!profile.balance} onPress={payoutMoney}>
                {intl.formatMessage({ id: 'settings.payouts.payout' })}
              </SpinnerButton>
            </View>
          )}
          <StripeBalance intl={intl} setIsError={setIsError} />
        </Card>
      </Modal>
    </View>
  );
};

export default PayoutsSettings;

const styles = StyleSheet.create({
  container: {
    maxWidth: 300,
  },
  contentCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  marginTop: { marginTop: 16 },
  setting: { padding: 16 },
  text: {
    textAlign: 'center',
    marginBottom: 10,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
