import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import authService from '../../../services/authService';
import { actions } from '../../../store/reducers';

export interface Profile {
  firstName: string;
  lastName: string;
  balance?: number;
  isChargesEnabled?: boolean;
  notificationsEnabled?: boolean;
  stripeAccountId?: string;
}

export const useEditSettings = (profile: Profile, onError) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [isChanged, setIsChanged] = useState(false);

  const [profileState, setProfileState] = useState<Profile>({
    firstName: profile.firstName,
    lastName: profile.lastName,
  });

  const onSave = async (values: Profile) => {
    setIsLoading(true);
    try {
      await authService.updateUser(values);

      dispatch(actions.setUserSettings());
    } catch (e) {
      console.log(e);
      onError();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let isProfileChanged = false;
    for (const prop in profileState) {
      if (profileState[prop] !== profile[prop]) {
        isProfileChanged = true;
        break;
      }
    }

    setIsChanged(isProfileChanged);
  }, [profileState, profile]);

  return {
    onSave,
    profileState,
    setProfileState,
    isChanged,
    isLoading,
  };
};
