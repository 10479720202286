import React from 'react';
import { Ionicons } from '@expo/vector-icons';

import { StyleSheet, Text, View, Platform } from 'react-native';

interface Props {
  size?: number;
  color?: string;
}

const WarningLockIcon = ({ size, color }: Props) => {
  return (
    <View style={styles.container}>
      <Ionicons
        name={Platform.OS === 'ios' ? 'ios-lock' : 'md-lock'}
        size={size || 24}
        color={color || 'black'}
      />
    </View>
  );
};

export default WarningLockIcon;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 4,
    backgroundColor: 'red',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
  },
});
