// import * as WebBrowser from 'expo-web-browser';
import React, { useEffect } from 'react';
import { Image, StyleSheet, View, AsyncStorage, AppState } from 'react-native';
import { connect } from 'react-redux';
import { Layout } from '@ui-kitten/components';

import { MonoText } from './../components/StyledText';
import { actions } from '../store/reducers';
import HomeScreenHeader, {
  HeaderRight,
  ConnectButton,
} from './home/HomeScreenHeader';
import ProgressBar from '../components/tasks/ProgressBar';
import DarkModal from '../components/tasks/DarkModal';
import authService from '../services/authService';
import { ModalMessage } from '../components/ModalMessage';
import Loader from '../components/Loader';
import { useSocket } from '../hooks/useSocket';
import TaskRouter from '../components/tasks/TaskRouter';
import TaskDescription from '../components/tasks/TaskDescription';
import { useIntl } from 'react-intl';

function HomeScreen({ navigation, isConnected, isServerShutdown, setUserSettings, task }) {
  const intl = useIntl();
  const {
    isLoading,
    message,
    setMessage,
    getTaskFromStorage,
    timeoutDelay,
    taskImage,
    handleAnswer,
    onTaskFinish,
    connectOnTask,
  } = useSocket(navigation);

  const IsConnectedImage = ({ isConnected }) =>
    isConnected ? (
      <Image
        source={require('./../assets/images/pending.png')}
        style={styles.waitingImage}
      />
    ) : (
      <Image
        source={require('./../assets/images/pending_colorless.png')}
        style={styles.waitingImage}
      />
    );

  const IsConnectedMessage = ({ isConnected, isServerShutdown }) =>
    isServerShutdown ? (
      <MonoText>{intl.formatMessage({ id: 'home.server_shutdown' })}</MonoText>
    ) :
    isConnected ? (
      <MonoText>{intl.formatMessage({ id: 'home.waiting' })}</MonoText>
    ) : (
      <View style={styles.message}>
        <MonoText>{intl.formatMessage({ id: 'home.offline' })} </MonoText>
        <MonoText>{intl.formatMessage({ id: 'home.push' })} </MonoText>
        <ConnectButton />
        <MonoText> {intl.formatMessage({ id: 'home.to_start' })}</MonoText>
      </View>
    );

  const checkToken = async () => {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      navigation.navigate('Auth');
      return;
    }

    try {
      const { data: user } = await authService.getUser();
      setUserSettings(user);
    } catch (e) {
      console.log(e);
    }

    getTaskFromStorage();
    connectOnTask();
  };

  useEffect(() => {
    checkToken();
    AppState.addEventListener('change', connectOnTask);
    return () => {
      AppState.removeEventListener('change', connectOnTask);
    };
  }, []);

  return (
    <Layout style={styles.container}>
      <ModalMessage
        message={message.message}
        visible={message.visible}
        setVisible={(visible: boolean) => setMessage({ ...message, visible })}
      />

      {!taskImage &&
        (isLoading ? (
          <Loader />
        ) : (
          <View style={styles.waitingContainer}>
            <IsConnectedImage isConnected={isConnected} />
            <IsConnectedMessage isConnected={isConnected} isServerShutdown={isServerShutdown} />
          </View>
        ))}

      {taskImage && task && (
        <View style={styles.contentContainer}>
          {task.timeout !== 0 && (
            <ProgressBar
              timeout={task.timeout - timeoutDelay}
              onFinish={onTaskFinish}
            />
          )}
          <DarkModal handleAnswer={handleAnswer} />
          {task.description && <TaskDescription task={task} />}
          <TaskRouter task={task} onAnswer={handleAnswer} />
        </View>
      )}
    </Layout>
  );
}

HomeScreen.navigationOptions = {
  headerTitle: () => <HomeScreenHeader />,
  headerRight: () => (
    <View style={{ marginRight: 20 }}>
      <HeaderRight />
    </View>
  ),
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  message: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  contentContainer: {
    flex: 1,
    position: 'relative',
    backgroundColor: 'black',
    justifyContent: 'center',
  },

  welcomeContainer: {
    position: 'relative',
    alignItems: 'center',
  },
  waitingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  waitingImage: {
    width: 153,
    height: 309,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightText: {
    color: 'rgba(96,100,109, 0.8)',
  },
  codeHighlightContainer: {
    backgroundColor: 'rgba(150,150,0,0.5)',
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    color: 'rgba(96,100,109, 1)',
    lineHeight: 24,
    textAlign: 'center',
  },
  navigationFilename: {
    marginTop: 5,
  },
  helpContainer: {
    marginTop: 15,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});

const mapStateToProps = (state) => {
  return {
    profile: state.settingsReducer,
    isConnected: state.homeReducer.isConnected,
    isServerShutdown: state.homeReducer.isServerShutdown,
    willUserStop: state.homeReducer.willUserStop,
    task: state.homeReducer.task,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsConnected: (isConnected: boolean) =>
      dispatch(actions.setIsConnected(isConnected)),
    setIsUserAsked: (bool: boolean) => dispatch(actions.setIsUserAsked(bool)),
    setTask: (task: object) => dispatch(actions.setTask(task)),
    setUserSettings: (user) => dispatch(actions.setUserSettings(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
