import React from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import { Text } from '@ui-kitten/components';

import { IconButton } from '../../components/IconButton';
import { actions } from '../../store/reducers';
import { useIntl } from 'react-intl';

const HomeScreenHeader = ({ isConnected, profile }) => {
  const intl = useIntl();
  return (
    <Text category="h6">
      {intl.formatMessage({ id: 'home.welcome' })},{' '}
      {profile && profile.firstName}!
    </Text>
  );
};

const UserStatus = ({
  isConnected,
  setIsConnected,
  setIsUserAsked,
  isTask,
}) => {
  const handleStatusButton = () => {
    if (isTask && isConnected) {
      return () => setIsUserAsked(true);
    } else if (!isTask && isConnected) {
      return () => setIsConnected(false);
    } else if (!isConnected) {
      return () => setIsConnected(true);
    }
  };

  return (
    <View style={styles.main}>
      <IconButton
        handleClick={handleStatusButton()}
        name={!isConnected ? 'md-play-circle' : 'ios-pause'}
        size={36}
        color="#2E95DB"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    marginHorizontal: 10,
    alignItems: 'center',
  },
  status: {
    marginRight: 10,
  },
});

const mapStateToProps = (state) => ({
  profile: state.settingsReducer,
  isConnected: state.homeReducer.isConnected,
  isTask: !!state.homeReducer.task,
});
const mapDispatchToProps = (dispatch) => ({
  setIsConnected: (isConnected: boolean) =>
    dispatch(actions.setIsConnected(isConnected)),
  setIsUserAsked: (isUserAsked: boolean) =>
    dispatch(actions.setIsUserAsked(isUserAsked)),
});

export const HeaderRight = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserStatus);

export const ConnectButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ setIsConnected }) => (
  <IconButton
    color="#2E95DB"
    size={26}
    handleClick={() => setIsConnected(true)}
    name="md-play-circle"
  />
));

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreenHeader);
