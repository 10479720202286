import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from 'react-navigation-stack';

import SignInScreen from '../screens/auth/signin/SignInScreen';
import PasswordSetScreen from '../screens/auth/signup/PasswordSet';
import PasswordResetScreen from '../screens/auth/PasswordReset/PasswordResetScreen';
import PasswordResetSuccess from '../screens/auth/PasswordReset/PasswordResetSuccess';

const config = Platform.select({
  web: { headerMode: 'hidden' },
  default: { headerMode: 'hidden' },
});

const AuthStack = createStackNavigator(
  {
    signin: SignInScreen,
    password: PasswordSetScreen,
    resetPassword: PasswordResetScreen,
    success: PasswordResetSuccess,
  },
  config,
);

export default AuthStack;
