export const SET_ALLOWED_APPLICATIONS = 'SET_ALLOWED_APPLICATIONS';
export const SET_PUBLISHED_APPLICATIONS = 'SET_PUBLISHED_APPLICATIONS';
export const RESET_PUBLISHED_APPLICATIONS = 'RESET_PUBLISHED_APPLICATIONS';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_IS_CONNECTED = 'SET_IS_CONNECTED';
export const SET_IS_USER_ASKED = 'SET_IS_USER_ASKED';
export const USER_DECLINE_TASK = 'USER_DECLINE_TASK';
export const USER_WILL_STOP = 'USER_WIIL_STOP';
export const SET_TASK = 'SET_TASK';
export const SET_IS_SERVER_SHUTDOWN = 'SET_IS_SERVER_SHUTDOWN';
