import React from 'react';
import { StyleSheet } from 'react-native';
import { KeyboardAvoidingView } from './extra/3rd-party';
import { ImageOverlay } from './extra/image-overlay.component';

const AuthImageOverlay = ({ children }) => {
  return (
    <KeyboardAvoidingView>
      <ImageOverlay
        style={styles.container}
        source={require('./assets/image-background.jpg')}
      >
        {children}
      </ImageOverlay>
    </KeyboardAvoidingView>
  );
};

export default AuthImageOverlay;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
