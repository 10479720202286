import React from 'react';
import { StyleSheet, View } from 'react-native';

import AuthImageOverlay from '../../../components/AuthImageOverlay';
import { Text } from '@ui-kitten/components';
import BigButton from '../../../components/BigButton';
import { useIntl } from 'react-intl';

const PasswordResetSuccess = ({ navigation }) => {
  const intl = useIntl();
  return (
    <AuthImageOverlay>
      <View style={styles.headerContainer}>
        <Text category="h2" status="control" style={styles.headerText}>
          {intl.formatMessage({ id: 'recovered.header' })}
        </Text>
        <Text category="h6" status="control" style={styles.headerText}>
          {intl.formatMessage({ id: 'recovered.check.email' })}
        </Text>
      </View>
      <BigButton
        title={intl.formatMessage({ id: 'back' })}
        onPress={() => navigation.navigate('signin')}
      />
    </AuthImageOverlay>
  );
};

export default PasswordResetSuccess;

const styles = StyleSheet.create({
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 216,
  },
  headerText: {
    textAlign: 'center',
  },
});
