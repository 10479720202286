import { AsyncStorage } from 'react-native';

export const determineRoute = async (navigation) => {
  const inviteId = await AsyncStorage.getItem('inviteId');
  if (inviteId) {
    navigation.navigate('details', {
      id: Number(inviteId),
    });
  } else {
    navigation.navigate('Main');
  }
};
