import React from 'react';
import { View, StyleSheet, Text, ActivityIndicator } from 'react-native';

type Props = {
  color?: string;
  backgroundColor?: string;
};
const Loader = ({ color, backgroundColor }: Props) => {
  return (
    <View
      style={[
        styles.container,
        { backgroundColor: backgroundColor || 'black' },
      ]}
    >
      <ActivityIndicator size="large" color={color || 'white'} />
    </View>
  );
};

export default Loader;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
  },
});
