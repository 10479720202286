import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Input } from '@ui-kitten/components';

interface Props {
  hint: string;
  value: string;
  placeholder: string;
  onChange: (value: any) => void;
}

const ProfileEditSetting = ({ hint, value, placeholder, onChange }: Props) => {
  return (
    <View style={styles.container}>
      <Text appearance="hint" category="s1">
        {hint}
      </Text>
      <Input
        value={value}
        placeholder={placeholder}
        onChangeText={(text) => onChange(text)}
      />
    </View>
  );
};

export default ProfileEditSetting;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 16,
  },
});
