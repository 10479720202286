import { useEffect } from 'react';
import { Platform } from 'react-native';
import { Task } from '../../../hooks/useTask';

export const useKeyboard = (task: Task, onAnswer) => {
  const handleKeyPress = (e) => {
    if (isNaN(e.key)) {
      return;
    }

    const labels = [...Object.keys(task.classes), 'skip'];

    if (Number(e.key) >= 1 && Number(e.key) <= labels.length) {
      const answer = labels[Number(e.key) - 1];
      onAnswer(answer !== 'skip' ? { answer } : { skip: true });
    }
  };

  useEffect(() => {
    Platform.OS === 'web' &&
      document.addEventListener('keypress', handleKeyPress);
    return () => {
      Platform.OS === 'web' &&
        document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return { handleKeyPress };
};
