import React, { useState, useEffect } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';
import { Dimensions } from 'react-native';

const WebZoomImage = ({ imageSrc }) => {
  const [screenWidth, setScreenWidth] = useState(Math.round(Dimensions.get('window').width));
  const [screenHeight, setScreenHeight] = useState(Math.round(Dimensions.get('window').height));

  const setSizes = () => {
    setScreenWidth(Math.round(Dimensions.get('window').width));
    setScreenHeight(Math.round(Dimensions.get('window').height));
  };

  useEffect(() => {
    Dimensions.addEventListener('change', setSizes);
    return () => {
      Dimensions.removeEventListener('change', setSizes);
    };
  }, []);

  const [size, setSize] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  });
  const [isDoubleClick, setIsDoubleClick] = useState(false);
  const [now, setNow] = useState(Date.now());

  const click = () => {
    const cur = Date.now();
    if (cur - now < 200) {
      setIsDoubleClick(true);
    }
    setNow(cur);
  };

  useEffect(() => {
    if (isDoubleClick) {
      setSize({
        scale: 1,
        translation: { x: 0, y: 0 },
      });
      setIsDoubleClick(false);
    }
  }, [isDoubleClick]);

  return (
    <MapInteractionCSS
      scale={size.scale}
      translation={size.translation}
      onChange={({ scale, translation }) => setSize({ scale, translation })}
    >
      <img
        onClick={click}
        src={imageSrc}
        style={{
          objectFit: 'contain',
          alignSelf: 'center',
          justifySelf: 'center',
          width: screenWidth,
          height: screenHeight - 52,
          cursor: 'pointer',
        }}
      />
    </MapInteractionCSS>
  );
};

export default WebZoomImage;
