import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';

import TabBarIcon from '../components/TabBarIcon';
import HomeScreen from '../screens/HomeScreen';
import SettingsScreen from '../screens/settings/SettingsScreen';
import ApplicationDetails from '../screens/applications/ApplicationDetails';
import ApplicationsScreen from '../screens/applications/ApplicationsScreen';

const config = Platform.select({
  web: { headerMode: 'screen' },
  default: {},
});

const HomeStack = createStackNavigator(
  {
    home: {
      screen: HomeScreen,
      path: '',
    },
  },
  config,
);

HomeStack.navigationOptions = ({ screenProps }) => ({
  tabBarLabel: screenProps.intl.formatMessage({ id: 'home' }),
  tabBarIcon: ({ focused }) => (
    <TabBarIcon
      focused={focused}
      name={
        Platform.OS === 'ios'
          ? `ios-information-circle${focused ? '' : '-outline'}`
          : 'md-information-circle'
      }
    />
  ),
});

const SettingsStack = createStackNavigator(
  {
    settings: {
      screen: SettingsScreen,
      path: '',
    },
  },
  config,
);

SettingsStack.navigationOptions = ({ screenProps }) => ({
  tabBarLabel: screenProps.intl.formatMessage({ id: 'settings' }),
  tabBarIcon: ({ focused }) => (
    <TabBarIcon
      focused={focused}
      name={Platform.OS === 'ios' ? 'ios-options' : 'md-options'}
    />
  ),
});

const ApplicationsStack = createStackNavigator(
  {
    applications: {
      screen: ApplicationsScreen,
      path: 'applications',
      params: { isInvite: undefined },
      navigationOptions: { title: '' },
    },
    details: {
      screen: ApplicationDetails,
      path: 'applications/:id',
      navigationOptions: { title: '' },
    },
  },
  config,
);

ApplicationsStack.navigationOptions = ({ screenProps }) => ({
  tabBarLabel: screenProps.intl.formatMessage({ id: 'applications' }),
  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="ios-apps" />,
});

const tabNavigator = createBottomTabNavigator(
  {
    home: {
      screen: HomeStack,
      path: '',
      // React Navigation set query params for all screens.
      // For this reason we set undefined params
      // https://github.com/react-navigation/react-navigation/issues/6674
      params: { isInvite: undefined },
    },
    applications: {
      screen: ApplicationsStack,
      navigationOptions: { title: '' },
    },
    settings: {
      screen: SettingsStack,
    },
  },
  { initialRouteName: 'home' },
);

export default tabNavigator;
