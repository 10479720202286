import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Modal, Text } from '@ui-kitten/components';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { actions } from '../../store/reducers';

const DarkModal = ({
  handleAnswer,
  isUserAsked,
  setIsUserAsked,
  declineTask,
  userWillStop,
}) => {
  const intl = useIntl();
  return (
    <View style={styles.container}>
      <Modal
        visible={isUserAsked}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setIsUserAsked(false)}
      >
        <Card disabled={true}>
          <Text style={styles.margin10}>
            {intl.formatMessage({ id: 'dark_modal.finish' })}
          </Text>
          <View style={styles.margin10}>
            <Button
              size="small"
              onPress={() => {
                setIsUserAsked(false);
                userWillStop(true);
              }}
            >
              {intl.formatMessage({ id: 'yes' })}
            </Button>
          </View>
          <View style={styles.margin10}>
            <Button size="small" onPress={() => declineTask(handleAnswer)}>
              {intl.formatMessage({ id: 'no' })}
            </Button>
          </View>
        </Card>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  margin10: {
    marginBottom: 10,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});

const mapStateToProps = (state) => {
  return {
    isUserAsked: state.homeReducer.isUserAsked,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsUserAsked: (isUserAsked: boolean) =>
      dispatch(actions.setIsUserAsked(isUserAsked)),
    declineTask: (handleAnswer) => {
      dispatch(actions.declineTask());
      handleAnswer({ skip: true });
    },
    userWillStop: (bool: boolean) => dispatch(actions.userWillStop(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DarkModal);
