import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Card } from '@ui-kitten/components';
import { ProfileSetting } from '../extra/profile-setting.component';
import authService from '../../../services/authService';
import { centToDollars } from '../../../utils/textUtils';
import { useIntl } from 'react-intl';
import { convertBalanceObject, Balance } from './convertBalanceObject';
import Loader from '../../../components/Loader';

export const StripeBalance = ({ intl, setIsError }) => {
  const [balanceObject, setBalanceObject] = useState<Balance>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStripeBalance = async () => {
    try {
      setIsLoading(true);
      const response = await authService.getUserBalance();
      setBalanceObject(convertBalanceObject(response.data));
    } catch (e) {
      setIsError(true);
      console.log(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchStripeBalance();
  }, []);

  if (isLoading) {
    return (
      <View style={styles.marginTop}>
        <Loader backgroundColor="white" color="black" />
      </View>
    );
  }

  if (!balanceObject) {
    return null;
  }

  return (
    <Card status="primary" style={styles.marginTop}>
      <ProfileSetting
        style={styles.setting}
        hint={intl.formatMessage({ id: 'settings.total_balance' })}
        value={balanceObject?.available}
      />
      <ProfileSetting
        style={styles.setting}
        hint={intl.formatMessage({ id: 'settings.pending_balance' })}
        value={balanceObject?.pending}
      />
      <ProfileSetting
        style={styles.setting}
        hint={intl.formatMessage({ id: 'settings.future_payouts' })}
        value={balanceObject?.total}
      />
    </Card>
  );
};

const styles = StyleSheet.create({
  marginTop: { marginTop: 16 },
  setting: { padding: 16 },
});
