import React, { useEffect, useState } from 'react';
import { View, StyleSheet, AsyncStorage } from 'react-native';
import { Button, Layout, Card, Text } from '@ui-kitten/components';
import { connect } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { ProfileSetting } from './extra/profile-setting.component';
import { CameraIcon } from './extra/icons';
import { useSafeArea } from './extra/3rd-party';
import authService from '../../services/authService';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { actions } from '../../store/reducers';
import { ModalMessage } from '../../components/ModalMessage';
import LanguageSelector from './LanguageSelector';
import EditSettingsScreen from './Edit/EditSettingsScreen';
import { useCheckToken } from '../../hooks/useCheckToken';
import PayoutsSettings from './PayoutSettings/PayoutsSettings';

function SettingsScreen({
  navigation,
  setUserSettings,
  profile,
  setIsConnected,
  setNotification,
}) {
  const intl = useIntl();
  const [isError, setIsError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const safeArea = useSafeArea();
  const checkToken = useCheckToken(navigation);

  const onLoad = async () => {
    await checkToken();

    setUserSettings();
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    AsyncStorage.setItem(
      'notificationsEnabled',
      JSON.stringify(profile.notificationsEnabled || false),
    );
  }, [profile.notificationsEnabled]);

  const onNotificationsToggle = async () => {
    let checked = profile.notificationsEnabled;

    try {
      checked = !checked;
      setNotification(checked);
      const response = await authService.updateUser({
        notificationsEnabled: checked,
      });
    } catch (e) {
      setIsError(true);
      setNotification(!checked);
      console.log(e);
    }
  };

  const onLogoutPress = async () => {
    setIsConnected(false);
    await AsyncStorage.removeItem('userToken');
    navigation && navigation.navigate('Auth');
  };

  // const renderBackAction = (): React.ReactElement => (
  //   <TopNavigationAction
  //     icon={ArrowIosBackIcon}
  //     onPress={navigation && navigation.goBack}
  //   />
  // );

  const renderPhotoButton = (): React.ReactElement => (
    <Button
      style={styles.photoButton}
      size="small"
      status="basic"
      accessoryLeft={CameraIcon}
    />
  );

  return (
    <Layout style={[styles.container, { paddingTop: safeArea.top }]} level="2">
      <ModalMessage
        message={intl.formatMessage({ id: 'server.error' })}
        visible={isError}
        setVisible={setIsError}
      />
      {/* <TopNavigation
        alignment='center'
        title='Settings'
        leftControl={renderBackAction()}
      /> */}
      {/* <Layout style={styles.photoSection}>
        <ProfileAvatar
          style={styles.photo}
          source={profile.photo}
          editButton={renderPhotoButton}
        />
        <View style={styles.nameSection}>
          <ProfileSetting
            style={styles.setting}
            value={profile.firstName}
          />
          <ProfileSetting
            style={styles.setting}
            value={profile.lastName}
          />
        </View>
      </Layout>
      <Text
        style={styles.description}
        appearance='hint'>
        {profile.description}
      </Text> */}
      {profile && (
        <Card style={styles.settingsContainer}>
          {!isEdit ? (
            <>
              <ProfileSetting
                style={[styles.setting]}
                hint={intl.formatMessage({ id: 'email' })}
                value={profile.email}
              />
              <ProfileSetting
                style={[styles.setting]}
                hint={intl.formatMessage({ id: 'settings.firstName' })}
                value={profile.firstName}
              />
              <ProfileSetting
                style={[styles.setting]}
                hint={intl.formatMessage({ id: 'settings.lastName' })}
                value={profile.lastName}
              />
              <ProfileSetting
                style={styles.setting}
                hint={intl.formatMessage({ id: 'settings.plan' })}
                value={'Enterprise'}
              />

              <LanguageSelector />
              <ToggleSwitch
                checked={profile.notificationsEnabled}
                onChange={onNotificationsToggle}
                label={intl.formatMessage({ id: 'settings.notifications' })}
              />
              <Button
                style={styles.editButton}
                status="basic"
                onPress={() => setIsEdit(true)}
              >
                {intl.formatMessage({ id: 'settings.edit' })}
              </Button>
              <PayoutsSettings profile={profile} setIsError={setIsError} />
            </>
          ) : (
            <EditSettingsScreen
              profile={profile}
              onCancel={() => setIsEdit(false)}
              onError={() => setIsError(true)}
            />
          )}
        </Card>
      )}
      {/* <ProfileSetting
        style={styles.setting}
        hint='Gender'
        value={profile.gender}
      />
      <ProfileSetting
        style={styles.setting}
        hint='Age'
        value={`${profile.age}`}
      />
      <ProfileSetting
        style={styles.setting}
        hint='Weight'
        value={`${profile.weight} kg`}
      />
      <ProfileSetting
        style={styles.setting}
        hint='Height'
        value={`${profile.height} cm`}
      /> */}
      <Button style={styles.doneButton} onPress={onLogoutPress}>
        {intl.formatMessage({ id: 'settings.logout' })}
      </Button>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingsButtons: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  photoSection: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  photo: {
    aspectRatio: 1.0,
    height: 76,
  },
  photoButton: {
    aspectRatio: 1.0,
    height: 32,
    borderRadius: 16,
  },
  nameSection: {
    flex: 1,
    marginHorizontal: 8,
  },
  description: {
    padding: 24,
    // backgroundColor: 'background-basic-color-1',
  },
  doneButton: {
    margin: 24,
    // position: 'absolute',
    // bottom: 100,
    minWidth: 270,
  },
  settingsContainer: {
    paddingHorizontal: 0,
    width: 270,
  },
  setting: {
    padding: 16,
  },
  emailSetting: {
    marginTop: 24,
  },
  editButton: {
    marginTop: 16,
  },
});

SettingsScreen.navigationOptions = {
  header: false,
};

const mapStateToProps = (state) => {
  return {
    profile: state.settingsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserSettings: () => dispatch(actions.setUserSettings()),
    setIsConnected: (bool) => dispatch(actions.setIsConnected(bool)),
    setNotification: (bool) => dispatch(actions.toggleNotifications(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);
