import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Modal, Text } from '@ui-kitten/components';
import SpinnerButton from './SpinnerButton';
import { useIntl } from 'react-intl';

export const ModalDialog = ({ message, visible, setVisible, onConfirm }) => {
  const intl = useIntl();
  const [isError, setIsError] = useState(false);
  const Dialog = () => (
    <View>
      <Text style={styles.text}>{message}</Text>
      <View style={styles.buttons}>
        <Button onPress={() => setVisible(false)} size="small">
          {intl.formatMessage({ id: 'cancel' })}
        </Button>

        <SpinnerButton
          onPress={async () => {
            try {
              await onConfirm();
              setVisible(false);
            } catch (e) {
              console.log(e);
              setIsError(true);
            }
          }}
          size="small"
        >
          OK
        </SpinnerButton>
      </View>
    </View>
  );

  const Error = () => (
    <View>
      <Text style={styles.text}>
        {intl.formatMessage({
          id: 'server.error',
        })}
      </Text>
      <Button
        size="small"
        onPress={() => {
          setIsError(false);
          setVisible(false);
        }}
      >
        Ok
      </Button>
    </View>
  );

  return (
    <View>
      <Modal
        visible={visible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => {
          setIsError(false);
          setVisible(false);
        }}
      >
        <Card style={styles.container} disabled={true}>
          {isError ? <Error /> : <Dialog />}
        </Card>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 300,
  },
  text: {
    textAlign: 'center',
    marginBottom: 10,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
});
