import { centToDollars } from '../../../utils/textUtils';

export interface Balance {
  total: string;
  available: string;
  pending: string;
}

interface BalanceObject {
  available: Array<{ amount: number }>;
  pending: Array<{ amount: number }>;
}

export const convertBalanceObject = (balanceObject: BalanceObject): Balance => {
  const pending = balanceObject.pending.reduce(
    (acc, cur) => acc + cur.amount,
    0,
  );

  const available = balanceObject.available.reduce(
    (acc, cur) => acc + cur.amount,
    0,
  );

  return {
    total: centToDollars(pending + available),
    pending: centToDollars(Math.abs(pending)),
    available: centToDollars(available),
  };
};
