import { Platform, AsyncStorage } from 'react-native';
import { Notifications } from 'expo';

export const sendNativeLocalNotification = async () => {
  const notificationsEnabled = await AsyncStorage.getItem('notificationsEnabled');
  if (notificationsEnabled && (Platform.OS === 'android' || Platform.OS === 'ios')) {
    Notifications.presentLocalNotificationAsync({
      title: 'New Task!',
      body: `You have a new task in MITM.AI`,
      ios: {
        sound: true,
      },
      android: {
        channelId: 'task-message',
      },
    });
  }
};
