import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { Button, Text } from '@ui-kitten/components';
import { useIntl, FormattedMessage } from 'react-intl';
import ProfileEditSetting from '../extra/profile-edit-setting.component';
import { Profile, useEditSettings } from './useEditSettings';

interface Props {
  onCancel: () => void;
  profile: Profile;
  onError: () => void;
}

const EditSettingsScreen = ({ onCancel, profile, onError }: Props) => {
  const intl = useIntl();

  const {
    onSave,
    setProfileState,
    profileState,
    isChanged,
    isLoading,
  } = useEditSettings(profile, onError);

  return (
    <View style={styles.container}>
      <Text category="h6">
        <FormattedMessage id="settings.edit" />
      </Text>
      <ProfileEditSetting
        hint={intl.formatMessage({ id: 'settings.firstName' })}
        value={profileState.firstName}
        placeholder={intl.formatMessage({ id: 'settings.firstName_example' })}
        onChange={(text) => {
          setProfileState({ ...profileState, firstName: text });
        }}
      />
      <ProfileEditSetting
        hint={intl.formatMessage({ id: 'settings.lastName' })}
        value={profileState.lastName}
        placeholder={intl.formatMessage({ id: 'settings.lastName_example' })}
        onChange={(text) => {
          setProfileState({ ...profileState, lastName: text });
        }}
      />
      <View style={styles.buttons}>
        <Button onPress={() => onCancel()}>
          {intl.formatMessage({ id: 'back' })}
        </Button>
        <Button
          disabled={isLoading || !isChanged}
          onPress={() => onSave(profileState)}
          accessoryLeft={isLoading ? () => <ActivityIndicator /> : null}
        >
          {intl.formatMessage({ id: 'ok' })}
        </Button>
      </View>
    </View>
  );
};

export default EditSettingsScreen;

const styles = StyleSheet.create({
  container: {},
  buttons: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
