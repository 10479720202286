import React, { useEffect } from 'react';
import {
  ActivityIndicator,
  AsyncStorage,
  StatusBar,
  View,
  Text,
} from 'react-native';
import { Linking } from 'expo';

export default function AuthLoadingScreen({ navigation }) {
  const checkToken = async () => {
    const url = await Linking.getInitialURL();
    const { queryParams } = Linking.parse(url);
    const token = queryParams.token;

    if (url.includes('signin')) {
      token && navigation.navigate('signin', { token });
      return;
    }
    if (url.includes('password')) {
      token && navigation.navigate('password', { token });
      return;
    }

    const userToken = await AsyncStorage.getItem('userToken');

    navigation.navigate(userToken ? 'Main' : 'Auth');

    console.log('AuthLoadingScreen');
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <View>
      <ActivityIndicator />
      <StatusBar barStyle="default" />
      <Text>Loading..</Text>
    </View>
  );
}
// export default class AuthLoadingScreen extends React.Component {
//   componentDidMount() {
//     this._bootstrapAsync();
//   }

//   // Fetch the token from storage then navigate to our appropriate place
//   _bootstrapAsync = async () => {
//     const userToken = await AsyncStorage.getItem('userToken');
//     this.props.navigation.navigate(userToken ? 'Main' : 'Auth');
//   };

//   // Render any loading content that you like here
//   render() {
//     return (
//       <View>
//         <ActivityIndicator />
//         <StatusBar barStyle="default" />
//         <Text>Loading..</Text>
//       </View>
//     );
//   }
// }
