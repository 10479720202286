export const initialState = {
  email: '',
  isSent: false,
  error: '',
};

export const actions = {
  changeEmail: (email) => ({
    type: 'CHANGE_EMAIL',
    payload: email,
  }),
  isSent: (bool) => ({
    type: 'SET_IS_SENT',
    payload: bool,
  }),
  setError: (error) => ({
    type: 'SET_ERROR',
    payload: error,
  }),
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_IS_SENT':
      return { ...state, isSent: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
