import React, { useEffect } from 'react';
import {
  StyleSheet,
  View,
  AsyncStorage,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { Button, Input, Text } from '@ui-kitten/components';
import { useIntl, FormattedMessage } from 'react-intl';

import authService from '../../../services/authService';
import AuthImageOverlay from '../../../components/AuthImageOverlay';
import BigButton from '../../../components/BigButton';
import { useSignIn } from './useSignIn';
import { EyeButton } from '../../../components/EyeButton';
import EmailIcon from '../../../components/EmailIcon';
import LockIcon from '../../../components/LockIcon';
import { determineRoute } from './determineRoute';

export default ({ navigation }): React.ReactElement => {
  const intl = useIntl();
  const {
    state,
    setProp,
    onChangeEmail,
    onChangePassword,
    onSignInButtonPress,
  } = useSignIn();

  const login = async (token) => {
    await AsyncStorage.setItem('userToken', token);
    authService.setUserToken(token);

    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      const expoPushToken = await AsyncStorage.getItem('expoPushToken');
      return authService.updateUser({ expoPushToken });
    }

    await determineRoute(navigation);
  };

  const onForgotPasswordButtonPress = (): void => {
    navigation && navigation.navigate('resetPassword');
  };

  useEffect(() => {
    const remoteToken = navigation.getParam('token', null);
    if (remoteToken) {
      login(remoteToken);
    }
  }, []);

  return (
    <AuthImageOverlay>
      <View style={styles.headerContainer}>
        <Text category="h1" status="control">
          {intl.formatMessage({ id: 'hello' })}
        </Text>
        <Text style={styles.signInLabel} category="s1" status="control">
          <FormattedMessage id="signIn.header" />
        </Text>
      </View>
      <View style={styles.formContainer}>
        <Input
          status="control"
          placeholder={intl.formatMessage({ id: 'email' })}
          value={state.email}
          onChangeText={onChangeEmail}
          accessoryLeft={() => <EmailIcon color="white" />}
        />
        <Input
          style={styles.passwordInput}
          status="control"
          placeholder={intl.formatMessage({ id: 'password' })}
          accessoryRight={() => (
            <EyeButton
              onPress={() =>
                setProp({ passwordVisible: !state.passwordVisible })
              }
              isEyeVisible={state.passwordVisible}
            />
          )}
          accessoryLeft={() => <LockIcon color="white" />}
          value={state.password}
          secureTextEntry={!state.passwordVisible}
          onChangeText={onChangePassword}
        />
        {state.error && (
          <Text style={styles.error} category="s1" status="control">
            {intl.formatMessage({ id: state.error })  }
          </Text>
        )}
        <View style={styles.forgotPasswordContainer}>
          <Button
            style={styles.forgotPasswordButton}
            appearance="ghost"
            status="control"
            onPress={onForgotPasswordButtonPress}
          >
            {intl.formatMessage({ id: 'signIn.forgot' })}
          </Button>
        </View>
      </View>
      <BigButton
        title={intl.formatMessage({ id: 'signIn.button' })}
        onPress={async () => {
          const token = await onSignInButtonPress();
          if (token) {
            login(token);
          }
        }}
        accessoryLeft={state.isLoading ? () => <ActivityIndicator /> : null}
      />
    </AuthImageOverlay>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 216,
  },
  formContainer: {
    flex: 1,
    marginTop: 32,
    paddingHorizontal: 16,
    minWidth: 300,
  },
  signInLabel: {
    marginTop: 16,
  },
  error: {
    color: 'red',
  },

  forgotPasswordContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  passwordInput: {
    marginTop: 16,
  },
  forgotPasswordButton: {
    paddingHorizontal: 0,
  },
  forgotPassword: {},
});
