import React from 'react';
import { View, Text, StyleSheet, Platform, ScrollView } from 'react-native';
import AnswerButton from './AnswerButton';
import { isMobileTablet } from '../../../utils/isMobileTablet';
import { useIntl } from 'react-intl';

const AllButtons = ({ task, onAnswer }) => {
  const intl = useIntl();
  const isDekstop = Platform.OS === 'web' && !isMobileTablet();
  return (
    <ScrollView
      horizontal={true}
      style={styles.horisontalScrollContainer}
      contentContainerStyle={styles.scrollContent}
    >
      <View style={styles.actionsBarContainer}>
        {Object.keys(task.classes).map((label, index) => {
          const isSelected = task.selected === label;
          return (
            <AnswerButton
              isDekstop={isDekstop}
              isSelected={isSelected}
              key={label}
              labelText={task.classes[label]}
              index={index}
              onAnswer={() => onAnswer({ answer: label })}
            />
          );
        })}
        <AnswerButton
          onAnswer={() => onAnswer({ skip: true })}
          labelText={intl.formatMessage({ id: 'skip' })}
          index={Object.keys(task.classes).length}
          isDekstop={isDekstop}
        />
      </View>
    </ScrollView>
  );
};

export default AllButtons;

const styles = StyleSheet.create({
  scrollContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  horisontalScrollContainer: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(96, 100, 109, 0.5)',
    paddingVertical: 20,
  },
  actionsBarContainer: {
    ...Platform.select({
      ios: {
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -3 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
      },
      android: {
        elevation: 20,
      },
    }),

    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
