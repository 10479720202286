import React from 'react';
import { View } from 'react-native';
import { IconButton } from '../../IconButton';

const AbsoluteRectangle = ({ coordinates, selfDelete, idx }) => {
  const { x1, y1, width, height } = coordinates;
  return (
    <View
      style={{
        position: 'absolute',
        left: x1,
        top: y1,
        width: width,
        height: height,
        borderStyle: 'dashed',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        borderRadius: 5,
        borderWidth: 2,
      }}
    >
      <View
        style={{
          position: 'absolute',
          ...((y1 < 25 && { bottom: -25 }) || { top: -25 }),
          ...((x1 < 25 && { right: -25 }) || { left: -25 }),
          width: 25,
          height: 25,
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 2,
          borderColor: 'black',
          borderRadius: 4,
          backgroundColor: 'rgba(178, 172, 141, 0.2)',
        }}
      >
        <IconButton handleClick={(e) => selfDelete(idx)} name="md-close" />
      </View>
    </View>
  );
};

export default AbsoluteRectangle;
