import React, { useState, useEffect } from 'react';
import { View, Animated, Easing } from 'react-native';

const ProgressBar = ({ timeout, onFinish }) => {
  const [timeLeft, setTimeLeft] = useState(new Animated.Value(100));

  const progress = timeLeft.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
  });

  const startAnimation = () => {
    timeLeft.setValue(100);
    Animated.timing(timeLeft, {
      toValue: 0, // Animate to final value of 1
      delay: 0,
      duration: timeout,
      easing: Easing.linear,
    }).start(onFinish);
  };

  useEffect(() => {
    startAnimation();
  }, []);

  return (
    timeout > 0 && (
      <View style={{ zIndex: 2 }}>
        <Animated.View style={{ width: progress, backgroundColor: 'red', height: 5 }} />
      </View>
    )
  );
};

export default ProgressBar;
