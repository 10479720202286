import React, { useEffect } from 'react';
import { StyleSheet, Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store/reducers';
import { ApplicationPreview } from './ApplicationPreview';
import { useIntl } from 'react-intl';
import NoContent from './NoContent';
import { useCheckToken } from '../../hooks/useCheckToken';

export const AllowedApplications = ({ navigation }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const applications = useSelector(
    (state) => state.applicationsReducer.allowedApplications,
  );
  const checkToken = useCheckToken(navigation);

  const onLoad = async () => {
    try {
      await checkToken();
      dispatch(actions.setApplicationsUserAllowedTo());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  return applications.length ? (
    <ScrollView
      style={
        Platform.OS !== 'web' ? styles.containerMobile : styles.containerWeb
      }
    >
      {applications.map((app) => (
        <ApplicationPreview
          isUserJoined={app.isUserJoined}
          isUserBlocked={app.isUserBlocked}
          application={app.application}
          key={app.id}
          navigation={navigation}
        />
      ))}
    </ScrollView>
  ) : (
    <NoContent
      message={intl.formatMessage({
        id: 'applications.no_allowed_applications',
      })}
    />
  );
};

const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
  },
  containerWeb: {
    flex: 1,
  },
  noApps: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    height: 50,
    backgroundColor: 'white',
    justifyContent: 'center',
    borderBottomColor: '#A7A7AA',
    borderBottomWidth: 1,
  },
  titleText: {
    textTransform: 'uppercase',
    fontSize: 25,
    fontWeight: '600',
  },
});
