import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Image,
  StyleSheet,
  Dimensions,
  PanResponder,
  Animated,
  Platform,
} from 'react-native';
import { Button } from '@ui-kitten/components/';
import AbsoluteRectangle from './AbsoluteRectangle';
import { useIntl } from 'react-intl';

const ImageArea = ({ task, onAnswer }) => {
  const intl = useIntl();
  const [screenWidth, setScreenWidth] = useState(
    Math.round(Dimensions.get('window').width),
  );
  const [screenHeight, setScreenHeight] = useState(
    Math.round(Dimensions.get('window').height),
  );
  const [imageSize, setImageSize] = useState<any>(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [newArea, setNewArea] = useState({
    x1: -5,
    y1: -5,
    width: 0,
    height: 0,
  });
  const [newAreaOffsetX] = useState(new Animated.Value(-5));
  const [newAreaOffsetY] = useState(new Animated.Value(-5));
  const [newAreaWidth] = useState(new Animated.Value(0));
  const [newAreaHeight] = useState(new Animated.Value(0));
  const imageContainerRef = useRef(null);
  const startPosition = (x: number, y: number) => {
    const { x1, y1, ...rest } = newArea;
    setNewArea({ x1: x, y1: y, ...rest });
  };

  const endPosition = (width: number, height: number) => {
    let { x1, y1 } = newArea;
    x1 = width > 0 ? x1 : x1 + width;
    y1 = height > 0 ? y1 : y1 + height;
    width = Math.abs(width);
    height = Math.abs(height);
    if (x1 < 0) {
      const diff = x1;
      x1 = 0;
      width += diff;
    }
    if (y1 < 0) {
      const diff = y1;
      y1 = 0;
      height += diff;
    }
    if (x1 + width > imageSize.width) {
      width = imageSize.width - x1;
    }
    if (y1 + height > imageSize.height) {
      height = imageSize.height - y1;
    }

    if (width > 20 && height > 20) {
      setSelectedAreas([
        ...selectedAreas,
        {
          x1,
          y1,
          width,
          height,
        },
      ]);
    }
  };

  const deleteArea = (idx) => {
    setSelectedAreas(
      selectedAreas.filter(
        (rectangle) => `${rectangle.width}${rectangle.height}` !== idx,
      ),
    );
  };

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: (evt, gestureState) => true,
    onPanResponderGrant: (evt, gestureState) => {
      newAreaOffsetX.setValue(evt.nativeEvent.pageX - offset.x);
      newAreaOffsetY.setValue(evt.nativeEvent.pageY - offset.y);
      startPosition(
        evt.nativeEvent.pageX - offset.x,
        evt.nativeEvent.pageY - offset.y,
      );
    },

    onPanResponderMove: (evt, gestureState) => {
      if (gestureState.dx < 0) {
        newAreaOffsetX.setValue(evt.nativeEvent.pageX - offset.x);
      }

      if (gestureState.dy < 0) {
        newAreaOffsetY.setValue(evt.nativeEvent.pageY - offset.y);
      }

      newAreaWidth.setValue(Math.abs(gestureState.dx));
      newAreaHeight.setValue(Math.abs(gestureState.dy));
    },

    onPanResponderRelease: (evt, gestureState) => {
      endPosition(gestureState.dx, gestureState.dy);
      newAreaOffsetX.setValue(-5);
      newAreaOffsetY.setValue(-5);
      newAreaWidth.setValue(0);
      newAreaHeight.setValue(0);
    },
  });

  const setScreenSizes = () => {
    setScreenWidth(Math.round(Dimensions.get('window').width));
    setScreenHeight(Math.round(Dimensions.get('window').height));
  };
  const preventDefault = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    Image.getSize(
      task.image,
      (width, height) => {
        if (height * (screenWidth / width) > screenHeight - 110) {
          setImageSize({
            width: Math.floor(width * ((screenHeight - 110) / height)),
            height: Math.floor(height * ((screenHeight - 110) / height)),
          });
        } else {
          setImageSize({
            width: Math.floor(width * (screenWidth / width)),
            height: Math.floor(height * (screenWidth / width)),
          });
        }
      },
      (e) => console.log(e),
    );
  }, [screenWidth, screenHeight]);

  useEffect(() => {
    Dimensions.addEventListener('change', setScreenSizes);
    Platform.OS === 'web' &&
      document.body.addEventListener('touchmove', preventDefault, {
        passive: false,
      });
    return () => {
      Dimensions.removeEventListener('change', setScreenSizes);
      Platform.OS === 'web' &&
        document.body.removeEventListener('touchmove', preventDefault);
    };
  }, []);

  return (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      {imageSize && (
        <View
          ref={imageContainerRef}
          style={{
            position: 'relative',
            width: imageSize.width,
            height: imageSize.height,
            alignSelf: 'center',
            zIndex: 2,
          }}
          onLayout={() => {
            imageContainerRef.current &&
              imageContainerRef.current.measure(
                (fx, fy, width, height, px, py) => {
                  setOffset({ x: px, y: py });
                },
              );
          }}
        >
          <Image
            source={{ uri: task.image }}
            style={{
              width: imageSize.width,
              height: imageSize.height,
              resizeMode: 'contain',
            }}
          />
          <Animated.View
            {...panResponder.panHandlers}
            style={{
              position: 'absolute',
              width: imageSize.width,
              height: imageSize.height,
              zIndex: 1,
            }}
          >
            {selectedAreas.map((rectangle) => (
              <AbsoluteRectangle
                coordinates={rectangle}
                idx={`${rectangle.width}${rectangle.height}`}
                key={`${rectangle.width}${rectangle.height}`}
                selfDelete={deleteArea}
              />
            ))}
            <Animated.View
              id="newRectangle"
              style={{
                position: 'absolute',
                left: newAreaOffsetX,
                top: newAreaOffsetY,
                width: newAreaWidth,
                height: newAreaHeight,
                borderStyle: 'dashed',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                borderRadius: 5,
                borderWidth: 2,
              }}
            ></Animated.View>
          </Animated.View>
        </View>
      )}
      <View style={styles.inputContainer}>
        <View style={styles.button}>
          <Button
            onPress={() => {
              sendAnswer(selectedAreas, task.image, imageSize, onAnswer);
            }}
            disabled={!selectedAreas.length}
          >
            {intl.formatMessage({ id: 'submit' })}
          </Button>
        </View>
        <View style={styles.button}>
          <Button onPress={() => onAnswer({ skip: true })}>Skip</Button>
        </View>
      </View>
    </View>
  );
};

async function sendAnswer(rectanglesArr, imageSrc, imageSize, onAnswer) {
  Image.getSize(
    imageSrc,
    (width, height) => {
      const scale = height / imageSize.height;
      const answer = rectanglesArr.map((rectangle) => {
        const realRectangle = [
          Math.floor(rectangle.x1 * scale),
          Math.floor(rectangle.y1 * scale),
          Math.floor((rectangle.x1 + rectangle.width) * scale),
          Math.floor((rectangle.y1 + rectangle.height) * scale),
        ];
        return realRectangle;
      });
      onAnswer({ answer: JSON.stringify(answer) });
    },
    (e) => console.log(e),
  );
}
const styles = StyleSheet.create({
  inputContainer: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: 20,
    zIndex: 5,
  },
  button: {
    marginLeft: 10,
  },
});
export default ImageArea;
