import io from 'socket.io-client';
import { AsyncStorage } from 'react-native';
import { WS } from './../constants/AppConstants';

let socket = null;

export default {
  connect: async (
    onMessage,
    onOpen,
    onClose,
    noAuth,
    onNewDevice,
    onTimeout,
    onReconnectFailed,
    onServerShutdown,
    isServerShutdown,
  ) => {
    const token = await AsyncStorage.getItem('userToken');

    socket = io.connect(WS, {
      query: { token },
      transports: ['websocket'],
      reconnectionAttempts: 15,
    });

    socket.on('connect', onOpen);
    socket.on('disconnect', (reason) => {
      if (reason === 'transport close' && isServerShutdown()) {
        return;
      }

      socket.removeAllListeners();
      onClose();
    });
    socket.on('unauthenticated', noAuth);
    socket.on('task', onMessage);
    socket.on('another_device', onNewDevice);
    socket.on('timeout_disconnect', onTimeout);
    socket.on('reconnect_failed', () => {
      socket.removeAllListeners();
      onReconnectFailed();
    });
    socket.on('server_shutdown', onServerShutdown);
    return socket;
  },
  stillAlive: () => {
    socket.emit('still-alive');
  },
  close: () => {
    if (socket) {
      socket.close();
      socket = null;
    }
  },
  status: () => socket.connected,
};
