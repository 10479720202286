import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Button } from '@ui-kitten/components';

const BigButton = ({ onPress, title, ...props }) => {
  return (
    <Button
      {...props}
      style={styles.button}
      status="control"
      size="giant"
      onPress={onPress}
    >
      {title.toUpperCase()}
    </Button>
  );
};

export default BigButton;

const styles = StyleSheet.create({
  button: {
    marginBottom: 50,
    width: 270,
  },
});
