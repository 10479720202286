import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, AsyncStorage } from 'react-native';
import { Card, Text, Button } from '@ui-kitten/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { centToDollars } from '../../utils/textUtils';
import { appService } from '../../services/applicationsService';
import { actions } from '../../store/reducers';
import { ModalDialog } from '../../components/ModalDialog';
import WarningLockIcon from '../../components/WarningLockIcon';
import HTML from "react-native-render-html";

const ApplicationDetails = ({ navigation }) => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const id = navigation.getParam('id', 'No app');
  const isInvite = navigation.getParam('isInvite', false);
  const [userStatus, setUserStatus] = useState({
    isUserJoined: false,
    isUserBlocked: false,
  });
  const [application, setApplication] = useState(null);

  const getApplication = async (id) => {
    try {
      const response = await appService.getApplicationById(id);

      const { application, isUserJoined, isUserBlocked } = response.data;
      setApplication(application);
      setUserStatus({ isUserJoined, isUserBlocked });
    } catch (e) {
      console.log(e);
    }
  };

  const onLoad = async () => {
    const token = await AsyncStorage.getItem('userToken');
    if (!token && isInvite) {
      await AsyncStorage.setItem('inviteId', id.toString());
    } else {
      await AsyncStorage.removeItem('inviteId');
      navigation.setParams({ isInvite: undefined });
    }
    await getApplication(id);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onConfirm = async () => {
    if (userStatus.isUserJoined) {
      await appService.removeApplicationFromUser(application.id);
    } else {
      await appService.addApplicationToUser(application.id);
    }

    await dispatch(actions.setApplicationsUserAllowedTo());
    await dispatch(actions.resetPublishedApplications());
    navigation.navigate('applications');
  };

  const Header = () => (
    <View style={styles.header}>
      <View style={styles.flexShrink1}>
        <Text style={styles.margin10} category="h5">
          {application.name}
        </Text>
        <Text category="s1">{application.description}</Text>
        <HTML source={{ html: application.instructions }} />
      </View>
      <Button
        disabled={!userStatus.isUserJoined && userStatus.isUserBlocked}
        style={styles.button}
        status={userStatus.isUserJoined ? 'danger' : 'success'}
        onPress={() => setVisible(true)}
      >
        {userStatus.isUserJoined ? 'Leave' : 'Join'}
      </Button>
    </View>
  );

  const Footer = () => {
    return (
      userStatus.isUserBlocked && (
        <View style={styles.footer}>
          <WarningLockIcon color="white" size={25} />
          <Text style={styles.footerText}>
            <FormattedMessage id="applications.will_block" />
          </Text>
        </View>
      )
    );
  };

  if (!application) {
    return null;
  }

  return (
    <Card
      style={styles.card}
      header={Header}
      footer={userStatus.isUserBlocked && Footer}
      status={userStatus.isUserJoined ? 'success' : 'primary'}
      disabled
    >
      <ModalDialog
        message={intl.formatMessage({
          id: userStatus.isUserJoined
            ? 'applications.leave_confirm'
            : 'applications.join_confirm',
        })}
        visible={visible}
        setVisible={setVisible}
        onConfirm={onConfirm}
      />

      { application.pricingModel === 'cloud' && 
        <View style={styles.content}>
          <Text category="s1">
            <FormattedMessage id="applications.price" />:{' '}
          </Text>
          <Text>{centToDollars(application.price)}</Text>
        </View>
      }
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    margin: 10,
  },
  margin10: {
    marginVertical: 10,
  },
  flexShrink1: {
    flexShrink: 1,
  },
  header: {
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: 10,
  },
  content: {
    flexDirection: 'row',
  },
  footer: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerText: {
    textAlignVertical: 'center',
    marginLeft: 20,
  },
});
export default ApplicationDetails;
