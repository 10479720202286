import { axiosInstance } from './authService';

export const appService = {
  getAllowedApplications: async () => {
    return await axiosInstance.get(`/user/applications`);
  },
  getPublishedApplications: async (page = 1) => {
    return await axiosInstance.get(`/user/published?page=${page}`);
  },

  removeApplicationFromUser: async (applicationId: number) => {
    return await axiosInstance.delete(`/user/applications/${applicationId}`);
  },

  addApplicationToUser: async (applicationId: number) => {
    return await axiosInstance.post(`/user/applications/${applicationId}`);
  },

  getApplicationById: async (applicationId) => {
    return await axiosInstance.get(`/user/applications/${applicationId}`);
  },
};
