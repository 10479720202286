import { combineReducers } from 'redux';
import { appService } from '../services/applicationsService';
import * as actionNames from './actionsNames';
import authService from '../services/authService';

export const actions = {
  setApplicationsUserAllowedTo: () => async (dispatch) => {
    try {
      const response = await appService.getAllowedApplications();
      dispatch({
        type: actionNames.SET_ALLOWED_APPLICATIONS,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  },
  setPublishedApplications: (page = 1) => async (dispatch) => {
    try {
      const response = await appService.getPublishedApplications(page);
      dispatch({
        type: actionNames.SET_PUBLISHED_APPLICATIONS,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  },
  resetPublishedApplications: () => async (dispatch) => {
    try {
      const response = await appService.getPublishedApplications(1);
      dispatch({
        type: actionNames.RESET_PUBLISHED_APPLICATIONS,
        payload: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  },
  setUserSettings: () => async (dispatch) => {
    try {
      const response = await authService.getUser();
      dispatch({ type: actionNames.SET_USER_SETTINGS, payload: response.data });
    } catch (e) {
      console.log(e);
    }
  },
  toggleLogin: (isLogged) => ({
    type: actionNames.TOGGLE_LOGIN,
    payload: isLogged,
  }),
  toggleNotifications: (isNotificationsOn) => ({
    type: actionNames.TOGGLE_NOTIFICATIONS,
    payload: isNotificationsOn,
  }),
  setLocale: (locale) => ({
    type: actionNames.SET_LOCALE,
    payload: locale,
  }),
  setIsConnected: (isConnected) => ({
    type: actionNames.SET_IS_CONNECTED,
    payload: isConnected,
  }),
  setIsUserAsked: (bool) => ({
    type: actionNames.SET_IS_USER_ASKED,
    payload: bool,
  }),
  declineTask: () => ({
    type: actionNames.USER_DECLINE_TASK,
  }),
  userWillStop: (bool) => ({
    type: actionNames.USER_WILL_STOP,
    payload: bool,
  }),
  setTask: (task) => ({
    type: actionNames.SET_TASK,
    payload: task,
  }),
  setIsServerShutdown: (task) => ({
    type: actionNames.SET_IS_SERVER_SHUTDOWN,
    payload: task,
  }),
};

const authReducer = (state = { loggedIn: false }, action) => {
  switch (action.type) {
    case actionNames.TOGGLE_LOGIN: {
      return {
        ...state,
        loggedIn: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

const applicationsReducer = (
  state = {
    allowedApplications: [],
    publishedApplications: { applicationsWithUserStatuses: [], count: 0 },
  },
  action,
) => {
  switch (action.type) {
    case actionNames.SET_ALLOWED_APPLICATIONS: {
      return { ...state, allowedApplications: [...action.payload] };
    }

    case actionNames.SET_PUBLISHED_APPLICATIONS: {
      const applications = [
        ...state.publishedApplications.applicationsWithUserStatuses,
        ...action.payload[0],
      ];

      return {
        ...state,
        publishedApplications: {
          applicationsWithUserStatuses: applications,
          count: action.payload[1],
        },
      };
    }

    case actionNames.RESET_PUBLISHED_APPLICATIONS: {
      return {
        ...state,
        publishedApplications: {
          applicationsWithUserStatuses: action.payload[0],
          count: action.payload[1],
        },
      };
    }

    default: {
      return state;
    }
  }
};

const settingsReducer = (state = { locale: 'en' }, action) => {
  switch (action.type) {
    case actionNames.TOGGLE_NOTIFICATIONS: {
      return {
        ...state,
        notificationsEnabled: action.payload,
      };
    }

    case actionNames.SET_USER_SETTINGS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case actionNames.SET_LOCALE: {
      return {
        ...state,
        locale: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

const homeReducer = (
  state = {
    isConnected: false,
    isUserAsked: false,
    willUserStop: false,
    isServerShutdown: false,
    task: null,
  },
  action,
) => {
  switch (action.type) {
    case actionNames.SET_IS_CONNECTED: {
      return {
        ...state,
        isUserAsked: false,
        willUserStop: false,
        isServerShutdown: false,
        isConnected: action.payload,
      };
    }
    case actionNames.SET_IS_USER_ASKED: {
      return {
        ...state,
        isUserAsked: action.payload,
      };
    }
    case actionNames.USER_DECLINE_TASK: {
      return {
        ...state,
        isUserAsked: false,
        isConnected: false,
      };
    }

    case actionNames.USER_WILL_STOP: {
      return {
        ...state,
        isUserAsked: false,
        willUserStop: true,
      };
    }

    case actionNames.SET_TASK: {
      return {
        ...state,
        task: action.payload,
      };
    }

    case actionNames.SET_IS_SERVER_SHUTDOWN: {
      return {
        ...state,
        isServerShutdown: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

const rootReducer = combineReducers({
  authReducer,
  settingsReducer,
  applicationsReducer,
  homeReducer,
});
export default rootReducer;
