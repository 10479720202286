import React from 'react';
import { StyleSheet, Platform, View } from 'react-native';
import { Card, Text } from '@ui-kitten/components';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { isMobileTablet } from '../../utils/isMobileTablet';
import WarningLockIcon from '../../components/WarningLockIcon';

export const ApplicationPreview = ({
  application,
  navigation,
  isUserJoined,
  isUserBlocked,
}) => {
  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('details', {
            id: application.id,
          });
        }}
      >
        <Card style={styles.card} status={isUserJoined ? 'success' : 'primary'}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <Text style={styles.text}>{application.name}</Text>
            </View>
            {isUserBlocked && <WarningLockIcon color="white" size={25} />}
          </View>
        </Card>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    flex: 1,
    marginVertical: 20,
    marginHorizontal: 10,
    ...(!isMobileTablet() && { width: 500, alignSelf: 'center' }),
  },
  text: {
    textAlign: 'center',
    textAlignVertical: 'center',
    ...Platform.select({
      web: {
        fontSize: 20,
      },
    }),
  },
  button: {
    width: 100,
    alignSelf: 'flex-end',
  },
  warning: {
    width: 25,
    padding: 5,
    borderRadius: 4,
    backgroundColor: 'red',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
  },
  warningText: {
    color: 'white',
    marginRight: 10,
    textTransform: 'uppercase',
  },
});
