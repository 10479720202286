import React, { useReducer } from 'react';
import { StyleSheet, View } from 'react-native';
import { Input, Text } from '@ui-kitten/components';

import authService from '../../../services/authService';
import AuthImageOverlay from '../../../components/AuthImageOverlay';
import { reducer, initialState, actions } from './reducer';
import BigButton from '../../../components/BigButton';
import { isMobileTablet } from '../../../utils/isMobileTablet';
import { useIntl } from 'react-intl';

const PasswordResetScreen = ({ navigation }) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(reducer, initialState);

  const onSubmit = async (email: string) => {
    try {
      const response = await authService.recoverPassword(email);
      navigation.navigate('success');
    } catch (e) {
      dispatch(actions.setError(intl.formatMessage({ id: 'server.error' })));
      console.log(e);
    }
  };
  return (
    <AuthImageOverlay>
      <View style={styles.headerContainer}>
        <Text category="h1" status="control" style={styles.headerText}>
          {intl.formatMessage({ id: 'password.header' })}
        </Text>
      </View>
      <View style={styles.formContainer}>
        <Input
          keyboardType="email-address"
          style={styles.emailInput}
          status="control"
          placeholder={intl.formatMessage({ id: 'email' })}
          value={state.email}
          onChangeText={(value) => dispatch(actions.changeEmail(value))}
        />
        {state.error !== '' && (
          <Text style={styles.error} category="s1">
            {state.error}
          </Text>
        )}
      </View>
      <View style={styles.buttons}>
        <BigButton
          title={intl.formatMessage({ id: 'back' })}
          onPress={() => navigation && navigation.navigate('signin')}
        />
        <BigButton
          title={intl.formatMessage({ id: 'password.submit' })}
          onPress={() => onSubmit(state.email)}
        />
      </View>
    </AuthImageOverlay>
  );
};

export default PasswordResetScreen;

const styles = StyleSheet.create({
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 216,
  },
  headerText: {
    textAlign: 'center',
  },
  formContainer: {
    flex: 1,
    marginTop: 32,
    paddingHorizontal: 16,
    minWidth: 300,
  },
  error: {
    color: 'red',
  },
  button: {
    marginBottom: 50,
    minWidth: 270,
  },
  emailInput: {
    marginTop: 16,
  },
  buttons: {
    width: '100%',
    flexDirection: isMobileTablet() ? 'column' : 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});
