import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';
import { Platform, AppState, AsyncStorage } from 'react-native';
import authService from '../services/authService';
import { VAPID_PUBLIC_KEY } from '../constants/AppConstants';

export const registerForPushNotificationsAsync = async (): Promise<void> => {
  if (Constants.isDevice) {
    const { status: existingStatus } = await Permissions.getAsync(
      Permissions.NOTIFICATIONS,
    );
    let finalStatus = existingStatus;

    if (existingStatus !== 'granted') {
      const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
      finalStatus = status;
    }

    if (finalStatus !== 'granted') {
      console.log('Failed to get push token for push notification!');
      return;
    }
  } else {
    console.log('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    Notifications.createChannelAndroidAsync('task-message', {
      name: 'mitm',
      priority: 'max',
      sound: true,
      vibrate: [0, 250, 250, 250],
    });
  }

  const token = await Notifications.getExpoPushTokenAsync();

  if (token) {
    AsyncStorage.setItem('expoPushToken', token);
  }
};

export function handleNotification(notification) {
  if (Platform.OS === 'android' && AppState.currentState === 'active') {
    Notifications.dismissNotificationAsync(notification.notificationId);
  }

  if (notification.remote) {
    AsyncStorage.setItem('shouldCheckTask', JSON.stringify(true));
  }
}

export const registerForWebPushNotification = async () => {
  await Notification.requestPermission((status) => {
    console.log('Notification permission status: ', status);
  });

  const applicationServerKey = VAPID_PUBLIC_KEY;

  const registration = await navigator.serviceWorker.ready;

  if (!registration.pushManager) {
    console.log('The push manager is not supported.');
    return;
  }

  const push = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
  });
  authService.updateUser({ subscription: JSON.stringify(push) });
};

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
