import React, { useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { Button } from '@ui-kitten/components';

export default function SpinnerButton({ children, onPress, ...props }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      await onPress();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <Button
      {...props}
      onPress={handleClick}
      disabled={isLoading || props.disabled}
    >
      {isLoading ? (
        <>
          <ActivityIndicator animating size="large" />
        </>
      ) : (
        children
      )}
    </Button>
  );
}
