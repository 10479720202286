import React from 'react';
import { Text, View, ActivityIndicator, Platform } from 'react-native';
import { createMaterialTopTabNavigator } from 'react-navigation-tabs';
import PublishedApplications from './PublishedApplications';
import { AllowedApplications } from './AllowedApplications';
import Colors from '../../constants/Colors.js';

const ApplicationsScreen = createMaterialTopTabNavigator(
  {
    joined: AllowedApplications,
    published: PublishedApplications,
  },
  {
    tabBarOptions: {
      activeTintColor: Colors.tabIconSelected,
      showLabel: true,
      style: {
        backgroundColor: 'white',
      },
      labelStyle: {
        color: Colors.tabIconSelected,
      },
      indicatorStyle: {
        backgroundColor: Colors.tabIconSelected,
      },
    },

    navigationOptions: {
      ...Platform.select({ web: { header: false }, default: {} }),
    },
    initialRouteName: '',
    swipeEnabled: true,
    lazy: true,
    lazyPlaceholderComponent: () => (
      <View>
        <ActivityIndicator animating size="large" />
      </View>
    ),
  },
);

export default ApplicationsScreen;
