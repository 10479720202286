import { useIntl } from 'react-intl';

export function useDesktopNotifications() {
  const intl = useIntl();
  const displayNotification = (task: { type: string; image: string }) => {
    if (Notification.permission === 'granted' && !document.hasFocus()) {
      const title = intl.formatMessage({ id: 'messages.you_have_task' });
      const options = {
        body: `${intl.formatMessage({ id: 'type' })}: ${task.type}`,
        icon: task.image,
        image: task.image,
        silent: true,
      };
      const notification = new Notification(title, options);
      notification.onclick = () => {
        parent.focus();
        notification.close();
      };
    }
  };
  return displayNotification;
}
