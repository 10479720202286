import { AppLoading, Notifications as ExpoNotifications, Linking } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import React, { useState, useEffect } from 'react';
import {
  Platform,
  StatusBar,
  StyleSheet,
  View,
  AsyncStorage,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { mapping, light as lightTheme } from '@eva-design/eva';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { AppIconsPack } from './components/AppIconsPack';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { I18nProvider } from './i18n/I18nProvider';

import AppNavigator from './navigation/AppNavigator';
import authService from './services/authService';
import {
  registerForPushNotificationsAsync,
  handleNotification,
  registerForWebPushNotification,
} from './utils/registerForNotifications';
import { isMobileTablet } from './utils/isMobileTablet.js';

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      registerForPushNotificationsAsync();
      ExpoNotifications.addListener(handleNotification);
    }

    if (Platform.OS === 'web' && !isMobileTablet()) {
      registerForWebPushNotification();
    }
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return (
      <ApplicationProvider mapping={mapping} theme={lightTheme}>
        <IconRegistry icons={[EvaIconsPack, AppIconsPack]} />
        <SafeAreaProvider>
          <View style={styles.container}>
            {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
            <Provider store={store}>
              <I18nProvider>
                <AppNavigator />
              </I18nProvider>
            </Provider>
          </View>
        </SafeAreaProvider>
      </ApplicationProvider>
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([
      // require('./assets/images/robot-dev.png'),
      // require('./assets/images/robot-prod.png'),
    ]),
    Font.loadAsync({
      // This is the font that we are using for our tab bar
      ...Ionicons.font,
      // We include SpaceMono because we use it in HomeScreen.js. Feel free to
      // remove this if you are not using it in your app
      'space-mono': require('./assets/fonts/PTMono-Regular.ttf'),
    }),
    AsyncStorage.getItem('userToken')
      .then((token) => {
        if (token) {
          console.log('JWT Token:', token);
          authService.setUserToken(token);
          // axios.interceptors.request.use(
          //   (reqConfig) => {
          //     reqConfig.headers['x-auth-token'] = token;

          //     return reqConfig;
          //   },
          //   (err) => Promise.reject(err),
          // );
          // axios.defaults.headers['X-Auth-Token'] = token;
        }
      })
      .catch((e) => console.log(e)),
  ]);
}

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/sw.js')
      .then(() => {
        console.log('Service Worker registered successfully.');
        self.addEventListener('notificationclick', (event) => {
          parent.focus();
        });
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
      });
  }
}

Platform.OS === 'web' && registerServiceWorker();

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
